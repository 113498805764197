import React, { useState } from "react";
import { Form, Input } from "antd";
import newLogo from "../../assets/images/newLogo.png";
import "./index.css";
import axios from "axios";
import { switchEndpoint } from "../../redux/actions/endpoints";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const DeleteSwitchAccount = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setPhone(value);
    }
  };

  const payload = {
    phone: phone,
    password: password,
  };

  const handleDeleteAccount = () => {
    setLoading(true);
    axios
      .post(`${switchEndpoint}/public/user/initiate-delete`, payload)
      .then((response) => {
        setLoading(false);
        localStorage.setItem("phoneNumber", phone);
        toast.success("OTP generated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setPhone("");
        history.push("/delete-account-otp");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const validate = () => {
    if (!phone) {
      toast.error("Phone Number not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!password) {
      toast.error("Password not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    handleDeleteAccount();
  };

  return (
    <div>
      <div className="row noMarginPadding">
        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8 col-xs-12 PaddingLeft-10">
          <div className="container pl-4 pt-4 PaddingLeft-10">
            <div style={{ width: "150px" }}>
              <img src={newLogo} alt="logo" style={{ width: "100%" }} />
            </div>
            <div className="w-75 ml-5 mt-5 containerWidth">
              <h5 className="headerText headerh5">Delete Account</h5>
              <p className="paragraphText my-3" style={{ fontSize: "15px" }}>
                This would delete your Switch account permanently. You can only
                delete your account when your Switch wallet balance is N0.00.
              </p>
              <div className="form-group">
                <label className="label-control mb-0 labelStyle">
                  Phone Number
                </label>
                <input
                  type="text"
                  maxLength={11}
                  value={phone}
                  onChange={handleChange}
                  className="form-control inputStyle"
                  placeholder="Enter phone number"
                />
              </div>
              <div>
                <label className="label-control mb-0 labelStyle">
                  Enter Password
                </label>
                <Form>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  >
                    <Input.Password placeholder="*************" />
                  </Form.Item>
                </Form>
              </div>

              <button
                type="button"
                className="btn loginBtn btn-sm btn-block"
                onClick={validate}
                disabled={loading}
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-4 rightImg"></div>
      </div>
    </div>
  );
};

export default DeleteSwitchAccount;
