import React, { useState, useEffect } from "react";
import "./main.css";
import wallet from "../../../../../assets/images/walletBalance.png";
import capacity from "../../../../../assets/images/capacity.png";
import dropOff from "../../../../../assets/images/dropOfff.png";
import pick from "../../../../../assets/images/pick.png";
import avatar from "../../../../../assets/images/avatarr.png";
import calendar from "../../../../../assets/images/calendar.png";
import Clock from "../../../../../assets/images/clock.png";
import stat from "../../../../../assets/images/stat.png";
import chart from "../../../../../assets/images/chart.png";
import { useSelector, useDispatch } from "react-redux";
import { statAction } from "../../../../../redux/actions";
import Weight from "./components/weight";
import Cash from "./components/cash";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import { toast } from "react-toastify";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import DropCodeModal from "./components/modals/dropOffCode.js";
import PaymentReqModal from "./components/modals/dropOffCode.js/awaitt";
import PickUpModal from "./components/modals/pickUpMod";
import MapsUI from "./components/map";
import PaymentSuccessfulModal from "./components/modals/dropOffCode.js/paymentSuccessful";
import PaymentFailedModal from "./components/modals/dropOffCode.js/paymentFailed";
import PaymentRequestModal from "./components/modals/dropOffCode.js/paymentRequest";
import AuthenticateModal from "./components/modals/dropOffCode.js/authenticate";
import ShowPModal from "./components/modals/dropOffCode.js/showP";
import AuthenticateModal2 from "./components/modals/unregistered/authenticate";
import { getCurrentMonthName } from "../../../../../getCurrentMonth";
import { getWeightByMonth } from "../../../../../individualWeight";
import { getPricing } from "../../../../../pricingMethod.jsx";
import EomTable from "./components/modals/eom/index.js";

const MainArea = () => {
  const [pricing, setPricing] = useState({});
  const [capacityy, setCapacity] = useState(0);
  const [steps, setSteps] = useState(0);
  const details = JSON.parse(localStorage.getItem("centerInfo"));
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingA, setLoadingA] = useState(false);
  const [loadingR, setLoadingR] = useState(false);
  const [householdDrop, setHouseholdDrop] = useState([]);
  const [dropReq, setDropReq] = useState([]);
  const [dropOffData, setDropOffData] = useState([]);
  const { screen } = useSelector(({ stat }) => stat);
  const [balance, setBalance] = useState({});
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [stats2, setStats2] = useState([]);
  const [stats3, setStats3] = useState([]);
  const [stats4, setStats4] = useState([]);

  const [valueA, setValueA] = useState(0.0);
  const [valueB, setValueB] = useState(0.0);

  const [driverr, setDriverr] = useState([]);
  const [activeDriverr, setActiveDriverr] = useState([]);

  const [isManual, setIsManual] = useState(false);

  useEffect(() => {
    const manual = localStorage.getItem("manual_pricing");
    if (manual) {
      setIsManual(manual)
    }
  }, [])

  // console.log(typeof (isManual))

  const switchToCash = () => {
    dispatch(statAction.setStat("cash"));
  };
  const switchToWeight = () => {
    dispatch(statAction.setStat("weight"));
  };

  const [showEom, setShowEom] = useState(false);
  const handleCloseEom = () => setShowEom(false);
  const handleShowEom = () => setShowEom(true);

  const [showDrop, setShowDrop] = useState(false);
  const handleCloseDrop = () => setShowDrop(false);
  const handleShowDrop = () => setShowDrop(true);

  const [showP, setShowP] = useState(false);
  const handleCloseP = () => setShowP(false);
  const handleShowP = () => setShowP(true);

  const [showAwaiting, setShowAwaiting] = useState(false);
  const handleCloseAwaiting = () => setShowAwaiting(false);
  const handleShowAwaiting = () => setShowAwaiting(true);

  const [showPaymentS, setShowPaymentS] = useState(false);
  const handleClosePaymentS = () => setShowPaymentS(false);
  const handleShowPaymentS = () => setShowPaymentS(true);

  const [showPaymentF, setShowPaymentF] = useState(false);
  const handleClosePaymentF = () => setShowPaymentF(false);
  const handleShowPaymentF = () => setShowPaymentF(true);

  const [showPaymentR, setShowPaymentR] = useState(false);
  const handleClosePaymentR = () => setShowPaymentR(false);
  const handleShowPaymentR = () => setShowPaymentR(true);

  const [showAuthenticate, setShowAuthenticate] = useState(false);
  const handleCloseAuthenticate = () => setShowAuthenticate(false);
  const handleShowAuthenticate = () => setShowAuthenticate(true);

  const [showPaymentRR, setShowPaymentRR] = useState(false);
  const handleClosePaymentRR = () => setShowPaymentRR(false);
  const handleShowPaymentRR = () => setShowPaymentRR(true);

  const [showAuthenticate2, setShowAuthenticate2] = useState(false);
  const handleCloseAuthenticate2 = () => setShowAuthenticate2(false);
  const handleShowAuthenticate2 = () => setShowAuthenticate2(true);

  const getAllReq = () => {
    setLoadingA(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/all-dropoff-requests`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        setHouseholdDrop(response.data.data);
        setLoadingA(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setHouseholdDrop([]);
        setLoadingA(false);
      });
  };

  const getHouseholdReq = () => {
    setLoadingA(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/dropoff-requests/household`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data.data;
        setHouseholdDrop(result);
        setLoadingA(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        console.log(error);
        setHouseholdDrop([]);
        setLoadingA(false);
      });
  };

  const getAgentReq = () => {
    setLoadingA(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/dropoff-requests/agents`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data.data;
        setHouseholdDrop(result);
        setLoadingA(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setHouseholdDrop([]);
        setLoadingA(false);
      });
  };

  const getCorporateReq = () => {
    setLoadingA(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/dropoff-requests/corporate`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const result = response.data.data;
        setHouseholdDrop(result);
        setLoadingA(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setHouseholdDrop([]);
        setLoadingA(false);
      });
  };

  const getPickUpdReq = () => {
    setLoadingR(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/all-pickup-requests`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        setDropReq(response.data.data);
        setLoadingR(false);
      })
      .catch((error) => {
        toast.error(error.response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingR(false);
      });
  };

  const getPickupHouseholdReq = () => {
    // setChecked1(false);
    setLoadingR(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/pickup-requests/household`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setDropReq(response.data.data);
        const result = response.data.data;
        // const total = response.data.meta.last_page;
        // setPageCount2(total);
        setLoadingR(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getPickupCorporateReq = () => {
    // setChecked4(false);
    setLoadingR(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/pickup-requests/corporate`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setDropReq(response.data.data);
        // const result = response.data.data;
        // const total = response.data.meta.last_page;
        // setPageCount4(total);
        setLoadingR(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setDropReq([]);
        setLoadingR(false);
      });
  };

  const getPickupAgentReq = () => {
    // setChecked1(false);
    setLoadingR(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/pickup-requests/agents`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // console.log(response.data.data);
        setDropReq(response.data.data);
        // const result = response.data.data;
        // const total = response.data.meta.last_page;
        // setPageCount3(total);
        setLoadingR(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingR(false);
      });
  };


  const getDropOff = (code) => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/single-dropoff/${code}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        setDropOffData(response.data.data);
        localStorage.setItem("id", response.data.data.id);
        localStorage.setItem("reference", JSON.stringify(response.data.data));
        if (
          response.data.data.status === "initiated" &&
          response.data.data.owner.user_type === "unregistered"
        ) {
          handleShowPaymentRR();
          return;
        }
        if (response.data.data.status === "scheduled") {
          handleShowDrop();
        }
        if (response.data.data.status === "initiated") {
          handleShowAwaiting();
        }

        if (response.data.data.status === "accepted") {
          handleShowPaymentR();
        }
        // handleShowDrop();
      })
    // .catch((error) => {
    //   toast.error(error.response.data.message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     pauseOnHover: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // });
  };

  const getCapacity = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/dashboard-stats/center-capacity`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // console.log(response.data.overall_capacity);
        setCapacity(response.data.overall_capacity);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getWalletBalance = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/fetch-balance`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        setBalance(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  const calculateWeight = (method1, method2) => {
    const currentMonthName = getCurrentMonthName();
    const desiredMonth = currentMonthName;
    const weightForMonth1 = getWeightByMonth(desiredMonth, method1);
    if (weightForMonth1) {
      setValueA(Number(weightForMonth1));
    }

    const weightForMonth2 = getWeightByMonth(desiredMonth, method2);
    if (weightForMonth2) {
      setValueB(Number(weightForMonth2));
    }
  };

  const getStat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/dashboard-stats/agent-weights`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setStats(response.data);
        let response2 = response.data;
        axios
          .get(
            `${switchEndpoint}/aggregation-center/dashboard-stats/household-weights`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setStats2(response.data);
            calculateWeight(response.data, response2);
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });
          });

        // console.log(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getChat = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/dashboard-stats/agent-payments`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setStats3(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        // toast.error(error.response.data.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   pauseOnHover: true,
        //   progress: undefined,
        //   theme: "light",
        // });
      });
  };

  const getChat2 = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/dashboard-stats/household-payments`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setStats4(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getDrivers = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/drivers`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        const results = response.data;
        setDriverr(response.data);
        let active = response.data.filter(
          (x) => x.last_login_status === "active"
        );
        let inactive = response.data.filter(
          (x) => x.last_login_status === "inactive"
        );

        localStorage.setItem("driversProp", JSON.stringify(active));
        // setActiveDrivers(active);
        setActiveDriverr(active);
        // setInactiveDrivers(inactive);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getAllPricing = () => {
    setLoadingA(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/all-waste-categories`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log("waste categories ==>", response.data);
        setPricing(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingA(false);
      });
  };

  useEffect(() => {
    getAllPricing();
    getWalletBalance();
    getAllReq();
    getPickUpdReq();
    getCapacity();
    getStat();
    getChat();
    getChat2();
    getDrivers();
    // calculateWeight();
  }, []);

  // let priceInfo = getPricing(pricing, "mixed_plastics", 120);
  // console.log(priceInfo);
  // let price = getPrice(pricing, "mixed_plastics", 120);
  // console.log(price);

  useEffect(() => {
    if (showAwaiting) {
      setSteps(1)
    } else {
      setSteps(0)
    }
  }, [showAwaiting])
  return (
    <main>
      <div className="main__container">
        <div className="separatingDiv">
          <div>
            <div className="main__cards">
              <div className="cardsz">
                <div >
                  <div className="cards_img align-items-center" style={{ margin: "0 auto" }}>
                    <img src={wallet} alt="wallet" />
                  </div>
                  <div className="card_inners">

                    {loading ? (
                      <span className="" style={{ margin: "0 auto" }}>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </span>
                    ) : (
                      <h3 className="card_inners_amount mb-0">{balance?.chams_wallet ? `₦${balance?.chams_wallet?.account_balance}` : `₦${0}`}</h3>
                    )}
                    <p className="card_inners_p mb-0">Wallet Balance</p>
                  </div>
                </div>
              </div>

              <div className="cardsz">
                <div className="flex justify-content-center align-items-center">
                  <div className="cards_img" style={{ margin: "0 auto" }}>
                    <img src={capacity} alt="cap" />
                  </div>
                  <div className="card_inners">
                    {loading ? (
                      <span className="ml-2" style={{ margin: "0 auto" }}>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </span>
                    ) : (
                      <h4 className="card_inners_amount ml-2 mb-0">
                        <span style={{ color: "#c4da00" }}>
                          {Math.round((capacityy + Number.EPSILON) * 100) / 100}
                        </span>{" "}
                        <span style={{ fontWeight: "400", fontSize: "18px" }}>
                          of{" "}
                        </span>{" "}
                        95{" "}
                        <span style={{ fontWeight: "400", fontSize: "18px" }}>
                          tonnes
                        </span>
                      </h4>
                    )}
                    <p className="card_inners_p mb-0 ml-2">Capacity</p>
                  </div>
                </div>

              </div>
            </div>

            {/* <div className="requests">
              <div className="cardss">
                <div className="requestFlex mb-3">
                  <div className="" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <div className="cards_imgs">
                      <img src={Clock} alt="wallet" />
                    </div>
                    <div className="card_inners ml-3">
                      <p className="card_inners_p mb-0 ml-2"> EOM Payment Due </p>
                      {loading ? (
                        <span className="ml-2">
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      ) : (

                        <h3 className="card_inners_amount ml-2">{`₦${`49,034.00`}`}</h3>
                      )}
                    </div>
                  </div>
                  <div className="d-flex">
                    <p className="card_inners_p mb-0 mr-2">View Details</p>
                    <div
                      className="buttonLike"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // console.log(req)
                        handleShowEom();
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          margin: "auto",
                        }}
                      >
                        &#62;
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="requests">
              <div className="cardss">
                <div className="requestFlex mb-0">
                  <div className="d-flex align-items-center">
                    <div className="dropOff_image">
                      <img src={dropOff} alt="drop" />
                    </div>
                    <div>
                      <h4 className="dropOff__title ml-2 mt-1">Drop Off Requests</h4>
                    </div>

                  </div>
                  <div>
                    <p className="mb-0">{`${householdDrop.length} requests`}</p>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="ml-5">
                    <div className="spaceTop">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                          onClick={() => {
                            getAllReq();
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          All
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="option2"
                          onClick={() => {
                            getHouseholdReq();
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          Household
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio3"
                          value="option3"
                          onClick={() => {
                            getAgentReq();
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio3">
                          Agent
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio3"
                          value="option3"
                          onClick={() => {
                            getCorporateReq();
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio3">
                          Corporate Users
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {loadingA ? (
                  <div className="card_inners_amount mt-4 text-center">
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                ) : (
                  <div>
                    {householdDrop?.length !== 0 ? householdDrop.map((req) => {
                      return (
                        <div key={req.id}>
                          <div className="reqBg">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                <div className="d-flex align-items-center">
                                  <img src={avatar} alt={avatar} />
                                  <p className="mb-0 ml-2">
                                    {req?.owner?.name}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="d-flex">
                                  <div>
                                    <img src={calendar} alt={calendar} />
                                  </div>
                                  <p
                                    className="mb-0 ml-2"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {req.date_type}
                                  </p>
                                </div>
                                <div className="ml-2">
                                  <div
                                    className="buttonLike"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // console.log(req)
                                      getDropOff(req?.code);
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#fff",
                                        textAlign: "center",
                                        margin: "auto",
                                      }}
                                    >
                                      &#62;
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      );
                    }) : <p className="text-center">No Data Available</p>}
                  </div>
                )}
              </div>
            </div>

            <div className="requests">
              <div className="cardss">
                <div className="requestFlex">
                  <div className="d-flex align-items-center">
                    <div className="dropOff_image">
                      <img src={pick} alt="pick" />
                    </div>
                    <h4 className="dropOff__title mt-1">Pick Up Requests</h4>
                  </div>
                  <div>
                    <p className="mb-0">{`${dropReq.length} requests`}</p>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="ml-5">
                    <div className="spaceTop">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                          onClick={() => {
                            getPickUpdReq();
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          All
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="option2"
                          onClick={() => {
                            getPickupHouseholdReq();
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          Household
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio3"
                          value="option3"
                          onClick={() => {
                            getPickupAgentReq();
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio3">
                          Agent
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio3"
                          value="option3"
                          onClick={() => {
                            getPickupCorporateReq();
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio3">
                          Corporate Users
                        </label>
                      </div></div></div>
                </div>
                {loadingR ? (
                  <div className="card_inners_amount text-center">
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                ) : (
                  <div>
                    {dropReq?.length !== 0 ? dropReq.map((req) => {
                      return (
                        <div key={req.id}>
                          <div className="reqBg">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                <div className="d-flex align-items-center">
                                  <img src={avatar} alt="vatar" />
                                  <p className="mb-0 ml-2">{req?.owner?.name}</p>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="d-flex">
                                  <div>
                                    <img src={calendar} alt="calendar" />
                                  </div>
                                  <p
                                    className="mb-0 ml-2"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {req?.date_type}
                                  </p>
                                </div>
                                <div className="ml-2">
                                  <div
                                    className="buttonLike"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      localStorage.setItem(
                                        "pickUpDetails",
                                        JSON.stringify(req)
                                      );
                                      handleShowP();
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#fff",
                                        textAlign: "center",
                                        margin: "auto",
                                      }}
                                    >
                                      &#62;
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      );
                    }) : <p className="text-center">No Data Available</p>}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="requests">
              {screen === "weight" && (
                <Weight
                  stat={stat}
                  switchToCash={switchToCash}
                  switchToWeight={switchToWeight}
                  chart={chart}
                  stats={stats}
                  stats2={stats2}
                  valueA={valueA}
                  valueB={valueB}
                />
              )}
              {screen === "cash" && (
                <Cash
                  stat={stat}
                  switchToCash={switchToCash}
                  switchToWeight={switchToWeight}
                  chart={chart}
                  stats3={stats3}
                  stats4={stats4}
                />
              )}
            </div>

            {/* <div className="requests">
              <div className="cardss">
                <div className="requestFlex mb-2">
                  <div className="d-flex align-items-center">
                    <div className="dropOff_image">
                      <img src={dropOff} alt="pick" />
                    </div>
                    <h4 className="dropOff__title mt-1">Active Drivers</h4>
                  </div>
                  <div>
                    <p className="mb-0">{`${activeDriverr.length} / ${driverr.length} drivers active`}</p>
                  </div>
                </div>
                <div className="mapImage ml-4 mr-3" style={{ height: "85vh" }}>
                  <MapsUI />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <DropCodeModal
        showDrop={showDrop}
        handleCloseDrop={handleCloseDrop}
        dropOffData={dropOffData}
        handleShowAwaiting={handleShowAwaiting}
        steps={steps}
        setSteps={setSteps}
        handleShowPaymentRR={handleShowPaymentRR}
        pricing={pricing}
        isManual={isManual}
      />

      <PaymentReqModal
        showAwaiting={showAwaiting}
        handleCloseAwaiting={handleCloseAwaiting}
        dropOffData={dropOffData}
        steps={steps}
        setSteps={setSteps}
        getDropOff={getDropOff}
        getAllReq={getAllReq}
      />

      <PaymentRequestModal
        showPaymentR={showPaymentR}
        handleClosePaymentR={handleClosePaymentR}
        handleShowAuthenticate={handleShowAuthenticate}
      />

      <PaymentSuccessfulModal
        showPaymentS={showPaymentS}
        handleClosePaymentS={handleClosePaymentS}
      />

      <PaymentFailedModal
        showPaymentF={showPaymentF}
        handleClosePaymentF={handleClosePaymentF}
      />

      <AuthenticateModal
        showAuthenticate={showAuthenticate}
        handleCloseAuthenticate={handleCloseAuthenticate}
        handleShowPaymentF={handleShowPaymentF}
        handleShowPaymentS={handleShowPaymentS}
      />

      <PickUpModal showP={showP} handleCloseP={handleCloseP} />

      <EomTable showEom={showEom} handleCloseEom={handleCloseEom} />

      <ShowPModal
        showPaymentRR={showPaymentRR}
        handleClosePaymentRR={handleClosePaymentRR}
        handleShowAuthenticate2={handleShowAuthenticate2}
      />

      <AuthenticateModal2
        showAuthenticate2={showAuthenticate2}
        handleCloseAuthenticate2={handleCloseAuthenticate2}
        dropOffData={dropOffData}
        handleShowPaymentS={handleShowPaymentS}
        handleShowPaymentF={handleShowPaymentF}
      />
    </main>
  );
};

export default MainArea;
