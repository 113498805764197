import axios from "axios";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Avatar from "../../../../../../../../assets/images/avatarr.png";
import { switchEndpoint } from "../../../../../../../../redux/actions/endpoints";
import "./index.css";
import capitalize from "../../../../../../../../capitalize";

const PaymentReqModal = ({
  showAwaiting,
  handleCloseAwaiting,
  dropOffData,
  steps,
  setSteps,
  getDropOff,
  handleShowPaymentR,
  getAllReq
}) => {

  // console.log(steps)
  const dropOffsData = JSON.parse(localStorage.getItem("dropOff"));
  const reference = JSON.parse(localStorage.getItem("reference"));
  const dropOffDet = JSON.parse(localStorage.getItem("dropDet"));
  const payment = localStorage.getItem("paymentMethod");
  // console.log(dropOffData);

  const getDropOffs = (code) => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/single-dropoff/${code}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        localStorage.setItem("reference", JSON.stringify(response.data.data));
        if (response.data.data.status === "accepted") {
          handleCloseAwaiting();
          handleShowPaymentR();
          setSteps(0);
          localStorage.removeItem("dropOff");
        }
      })
      .catch(error => {
        // console.log(error)
        handleCloseAwaiting();
        setSteps(0);
        localStorage.removeItem("dropOff");
        getAllReq();
        // window.location.reload();
      });
  };

  useEffect(() => {
    if (dropOffData?.code && steps === 1) {
      const interval = setInterval(() => {
        getDropOffs(dropOffData?.code)
      }, 1000 * 5);

      return () => clearInterval(interval);  // Clear interval on component unmount
    }
  }, [steps === 1]);

  return (
    <div>
      <Modal
        show={showAwaiting}
        onHide={() => {
          handleCloseAwaiting();
          setSteps(0)
          window.location.reload();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h5 className="sec-headers mb-3">Waiting for confirmation</h5>
              <div className="d-flex justify-content-between align-items-center">
                <p className="ftsz-regular" style={{ fontSize: "14px" }}>
                  {dropOffData ? capitalize(dropOffData?.owner?.roles[0]) : capitalize(reference?.owner?.roles[0])}:
                  {dropOffData
                    ? dropOffData?.owner?.name
                    : reference?.owner?.name}
                </p>
                <div className="d-flex align-items-center ">
                  <div>
                    <p
                      className="mb-0"
                      style={{ fontSize: "14px", color: "#898989" }}
                    >
                      Drop off Code
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#404040",
                        fontWeight: "700",
                      }}
                    >
                      {dropOffData ? dropOffData?.code : reference?.code}
                    </p>
                  </div>
                  <div className="imgAvatarHolder ml-3">
                    <img src={Avatar} alt="logoimage" />
                  </div>
                </div>
              </div>

              {(dropOffsData?.clean_sorted?.KG > 0 ||
                reference?.dropoff_details?.clean_sorted?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Clean Sorted</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropOffsData
                            ? `${dropOffsData?.clean_sorted?.KG}kg`
                            : `${reference?.dropoff_details?.clean_sorted?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {dropOffsData
                            ? `₦${dropOffsData?.clean_sorted?.amount?.toFixed(2)}`
                            : `₦${reference?.dropoff_details?.clean_sorted?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropOffsData?.clean_unsorted?.KG > 0 ||
                reference?.dropoff_details?.clean_unsorted?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Clean Unsorted</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropOffsData
                            ? `${dropOffsData?.clean_unsorted?.KG}kg`
                            : `${reference?.dropoff_details?.clean_unsorted?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {dropOffsData
                            ? `₦${dropOffsData?.clean_unsorted?.amount?.toFixed(
                              2
                            )}`
                            : `₦${reference?.dropoff_details?.clean_unsorted?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropOffsData?.mixed_plastics?.KG > 0 ||
                reference?.dropoff_details?.mixed_plastics?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Mixed Plastics</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropOffsData
                            ? `${dropOffsData?.mixed_plastics?.KG}kg`
                            : `${reference?.dropoff_details?.mixed_plastics?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {dropOffsData
                            ? `₦${dropOffsData?.mixed_plastics?.amount?.toFixed(
                              2
                            )}`
                            : `₦${reference?.dropoff_details?.mixed_plastics?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropOffsData?.pure_water_sachet_dry?.KG > 0 ||
                reference?.dropoff_details?.pure_water_sachet_dry?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Pure Water Nylon</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropOffsData
                            ? `${dropOffsData?.pure_water_sachet_dry?.KG}kg`
                            : `${reference?.dropoff_details?.pure_water_sachet_dry?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {dropOffsData
                            ? `₦${dropOffsData?.pure_water_sachet_dry?.amount?.toFixed(
                              2
                            )}`
                            : `₦${reference?.dropoff_details?.pure_water_sachet_dry?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropOffsData?.single_colour_caps?.KG > 0 ||
                reference?.dropoff_details?.single_colour_caps?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Single color caps</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropOffsData
                            ? `${dropOffsData?.single_colour_caps?.KG}kg`
                            : `${reference?.dropoff_details?.single_colour_caps?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {dropOffsData
                            ? `₦${dropOffsData?.single_colour_caps?.amount?.toFixed(
                              2
                            )}`
                            : `₦${reference?.dropoff_details?.single_colour_caps?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropOffsData?.hard_packing_bags_dry?.KG > 0 ||
                reference?.dropoff_details?.hard_packing_bags_dry?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Hard packing bags (dry)</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropOffsData
                            ? `${dropOffsData?.hard_packing_bags_dry?.KG}kg`
                            : `${reference?.dropoff_details?.hard_packing_bags_dry?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {dropOffsData
                            ? `₦${dropOffsData?.hard_packing_bags_dry?.amount?.toFixed(
                              2
                            )}`
                            : `₦${reference?.dropoff_details?.hard_packing_bags_dry?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropOffsData?.hdpe_plastics_below_five_meters?.KG > 0 ||
                reference?.dropoff_details?.hdpe_plastics_below_five_meters
                  ?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">HDPE plastics below 5 Litres</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropOffsData
                            ? `${dropOffsData?.hdpe_plastics_below_five_meters?.KG}kg`
                            : `${reference?.dropoff_details?.hdpe_plastics_below_five_meters?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {dropOffsData
                            ? `₦${dropOffsData?.hdpe_plastics_below_five_meters?.amount?.toFixed(
                              2
                            )}`
                            : `₦${reference?.dropoff_details?.hdpe_plastics_below_five_meters?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropOffsData?.pp_plastics?.KG > 0 ||
                reference?.dropoff_details?.pp_plastics?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">PP Plastics</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropOffsData
                            ? `${dropOffsData?.pp_plastics?.KG}kg`
                            : `${reference?.dropoff_details?.pp_plastics?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {dropOffsData
                            ? `₦${dropOffsData?.pp_plastics?.amount?.toFixed(2)}`
                            : `₦${reference?.dropoff_details?.pp_plastics?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropOffsData?.packing_bags?.KG > 0 ||
                reference?.dropoff_details?.packing_bags?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Pure Water Packaging Bags</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropOffsData
                            ? `${dropOffsData?.packing_bags?.KG}kg`
                            : `${reference?.dropoff_details?.packing_bags?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {dropOffsData
                            ? `₦${dropOffsData?.packing_bags?.amount?.toFixed(2)}`
                            : `₦${reference?.dropoff_details?.packing_bags?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {(dropOffsData?.cans?.KG > 0 ||
                reference?.dropoff_details?.cans?.KG > 0) && (
                  <div>
                    <div className="reqBg d-flex pl-4 w-50">
                      <p className="mb-0 mr-3">Cans</p>
                      <div className="d-flex">
                        <p className="fontBold">
                          {dropOffsData
                            ? `${dropOffsData?.cans?.KG}kg`
                            : `${reference?.dropoff_details?.cans?.KG}kg`}
                        </p>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <p className="fontBold">
                          {dropOffsData
                            ? `₦${dropOffsData?.cans?.amount?.toFixed(2)}`
                            : `₦${reference?.dropoff_details?.cans?.amount?.toFixed(
                              2
                            )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              <div className="mb-3 mr-3 w-50">
                <label className="mb-1 form-label">Deductions</label>
                <input
                  type="text"
                  className="form-control form-control-lg inputStyle bg-dark text-white"
                  placeholder={
                    dropOffsData
                      ? `₦${dropOffsData?.total_deduction?.toFixed(2)}`
                      : `₦${reference?.dropoff_details?.total_deduction?.toFixed(
                        2
                      )}`
                  }
                  disabled
                />
              </div>

              <div>
                <div className="totalBg d-flex pl-4 w-50">
                  <p className="mb-0 mr-3">Sum Total</p>
                  <div className="d-flex">
                    <p className="fontBold mb-0">
                      {dropOffsData
                        ? `${dropOffsData?.total_kg}kg`
                        : `${reference?.dropoff_details?.total_kg}kg`}
                    </p>
                    <span className="mx-3" style={{ color: "#c4da00" }}>
                      &#8644;
                    </span>
                    <p className="fontBold mb-0">
                      {dropOffsData
                        ? `₦${dropOffsData?.total_price?.toFixed(2)}`
                        : `₦${reference?.dropoff_details?.total_price?.toFixed(
                          2
                        )}`}
                    </p>
                  </div>
                </div>
              </div>

              <div className="my-4 d-flex">
                <p>
                  Payment Method :
                </p>
                <p className="ml-2">
                  {dropOffData?.owner?.roles[0] === "corporate" && "Transfer"}
                  {dropOffData?.owner?.roles[0] !== "corporate" && <p>{dropOffData?.payment_method ? dropOffData?.payment_method === "transfer" ? "Switch Transfer" : "Cash" : capitalize(payment)}</p>}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentReqModal;
