import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./index.css";
import calendar from "../../../../../assets/images/calendar.png";
import avatar from "../../../../../assets/images/avatarr.png";
import axios from "axios";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import { toast } from "react-toastify";
import PickUpModal from "./components/modals/dropOffCode.js";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import Resultss from "./components/Result";

const Pick = ({ setTitleName }) => {
  const [loading, setLoading] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [loadingS, setLoadingS] = useState(false);
  const [loadingD, setLoadingD] = useState(false);
  const [dropOff, setDropOff] = useState([]);
  const [stats, setStats] = useState({});
  const [driversList, setDriversList] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [groupedDate, setGroupedDate] = useState({});
  const othersObj = {};
  const [pageCount1, setPageCount1] = useState(0);
  const [pageCount2, setPageCount2] = useState(0);
  const [pageCount3, setPageCount3] = useState(0);
  const [pageCount4, setPageCount4] = useState(0);

  // const request = [
  //   {
  //     name: "Victor Okoye",
  //     imageUrl: <img src={avatar} alt="avatarr" />,
  //     date: "29/03/22",
  //   },
  //   {
  //     name: "Victor Okoye",
  //     imageUrl: { avatar },
  //     date: "29/03/22",
  //   },
  //   {
  //     name: "Victor Okoye",
  //     imageUrl: { avatar },
  //     date: "29/03/22",
  //   },
  //   {
  //     name: "Victor Okoye",
  //     imageUrl: { avatar },
  //     date: "29/03/22",
  //   },
  // ];

  const [showDrop, setShowDrop] = useState(false);
  const handleCloseDrop = () => setShowDrop(false);
  const handleShowDrop = () => setShowDrop(true);

  const getHouseholdReq = (data) => {
    setChecked1(false);
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/pickup-requests/household?page=${data}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setDropOff(response.data.data);
        const result = response.data.data;
        const total = response.data.meta.last_page;
        setPageCount2(total);
        result.forEach((element) => {
          const date_type = element.date_type;
          if (date_type in othersObj) {
            othersObj[date_type] = [...othersObj[date_type], element];
          } else {
            othersObj[date_type] = [element];
          }
        });
        setGroupedDate(othersObj);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getCorporateReq = (data) => {
    setChecked4(false);
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/pickup-requests/corporate?page=${data}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setDropOff(response.data.data);
        const result = response.data.data;
        const total = response.data.meta.last_page;
        setPageCount4(total);
        result.forEach((element) => {
          const date_type = element.date_type;
          if (date_type in othersObj) {
            othersObj[date_type] = [...othersObj[date_type], element];
          } else {
            othersObj[date_type] = [element];
          }
        });
        setGroupedDate(othersObj);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setDropOff([]);
        setLoading(false);
      });
  };

  const getAgentReq = (data) => {
    setChecked1(false);
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/pickup-requests/agents?page=${data}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // console.log(response.data.data);
        setDropOff(response.data.data);
        const result = response.data.data;
        const total = response.data.meta.last_page;
        setPageCount3(total);
        result.forEach((element) => {
          const date_type = element.date_type;
          if (date_type in othersObj) {
            othersObj[date_type] = [...othersObj[date_type], element];
          } else {
            othersObj[date_type] = [element];
          }
        });
        setGroupedDate(othersObj);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getAllReq = (data) => {
    setChecked1(true);
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/all-pickup-requests?page=${data}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setDropOff(response.data.data);
        const result = response.data.data;
        const total = response.data.meta.last_page;
        setPageCount1(total);
        // console.log("this is the total", total);
        result.forEach((element) => {
          const date_type = element.date_type;
          if (date_type in othersObj) {
            othersObj[date_type] = [...othersObj[date_type], element];
          } else {
            othersObj[date_type] = [element];
          }
        });
        setGroupedDate(othersObj);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  const getStat = () => {
    setLoadingS(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/all-pickup-today`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setStats(response.data);
        localStorage.setItem("stats", JSON.stringify(response.data));
        setLoadingS(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingS(false);
      });
  };

  const getDrivers = () => {
    setLoadingD(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/drivers`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        setDriversList(response.data);
        // localStorage.setItem("stats", JSON.stringify(response.data));
        setLoadingD(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingD(false);
      });
  };

  const assignDriver = (id, code) => {
    setLoadingD(true);
    const token = localStorage.getItem("token");
    axios
      .post(
        `${switchEndpoint}/aggregation-center/assign-driver/${code}`,
        {
          driver_id: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // console.log(response.data);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        getAllReq();
        setLoadingD(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingD(false);
      });
  };

  useEffect(() => {
    setTitleName("Pick Up Requests");
    setChecked1(true);
    getAllReq(1);
    getDrivers();
    getStat();
    // console.log(groupedDate);
  }, []);

  const handlePageClick1 = async (data) => {
    let currentPage = data.selected + 1;
    // const loadedData = await getAllReq(currentPage);
    getAllReq(currentPage);
    // setDropOff(response.data.data);
    console.log(data.selected);
  };

  const handlePageClick2 = async (data) => {
    let currentPage = data.selected + 1;
    // const loadedData = await getAllReq(currentPage);
    getHouseholdReq(currentPage);
    // setDropOff(response.data.data);
    console.log(data.selected);
  };

  const handlePageClick3 = async (data) => {
    let currentPage = data.selected + 1;
    // const loadedData = await getAllReq(currentPage);
    getAgentReq(currentPage);
    // setDropOff(response.data.data);
    console.log(data.selected);
  };

  const handlePageClick4 = async (data) => {
    let currentPage = data.selected + 1;
    // const loadedData = await getAllReq(currentPage);
    getCorporateReq(currentPage);
    // setDropOff(response.data.data);
    console.log(data.selected);
  };
  return (
    <main>
      <div className="main__container">
        <div className="separatingDivs">
          <div>
            <div className="main__cardss fullDiv">
              <div className="cardsss cards_extra">
                {loadingS ? (
                  <div className="card_inners_amount mt-4 text-center">
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                ) : (
                  <div className="card_inners">
                    <h2
                      className="card_inners_amount"
                      style={{ color: "#c4da00", fontSize: "32px" }}
                    >
                      <span style={{ color: "#c4da00" }}>
                        {stats?.today_pickups_completed}
                      </span>
                      <span style={{ fontWeight: "400", fontSize: "20px" }}>
                        {` of `}
                      </span>
                      {stats?.today_pickups}
                    </h2>
                    <p className="card_inners_p mb-0">
                      Pick Ups completed today
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <div className="requestss">
                <div className="cardss">
                  <div className=" mb-3">
                    <div className="d-flex align-items-center blockWay">
                      <h4 className="dropOff__title mt-1 mr-4">
                        <b>Scheduled Pick Ups</b> ({dropOff?.length})
                      </h4>

                      <div className="spaceTop">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="option1"
                            checked={checked1 ? true : false}
                            onClick={() => {
                              getAllReq(1);
                              setChecked1(true);
                              setChecked2(false);
                              setChecked3(false);
                              setChecked4(false);
                            }}
                          />
                          <label class="form-check-label" for="inlineRadio1">
                            All
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="option2"
                            onClick={() => {
                              getHouseholdReq(1);
                              setChecked1(false);
                              setChecked2(true);
                              setChecked3(false);
                              setChecked4(false);
                            }}
                          />
                          <label class="form-check-label" for="inlineRadio2">
                            Household
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio3"
                            value="option3"
                            onClick={() => {
                              getAgentReq(1);
                              setChecked1(false);
                              setChecked2(false);
                              setChecked3(true);
                              setChecked4(false);
                            }}
                          />
                          <label class="form-check-label" for="inlineRadio3">
                            Agent
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio3"
                            value="option3"
                            onClick={() => {
                              getCorporateReq(1);
                              setChecked1(false);
                              setChecked2(false);
                              setChecked3(false);
                              setChecked4(true);
                            }}
                          />
                          <label class="form-check-label" for="inlineRadio3">
                            Corporate
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mt-3">
                        {loading ? (
                          <div className="card_inners_amount mt-4 text-center">
                            <span>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        ) : (
                          <div>
                            {!Object.keys(groupedDate).length ? (
                              <p className="text-center">No Data Available</p>
                            ) : (
                              <div>
                                {Object.keys(groupedDate).length &&
                                  Object.keys(groupedDate).map((req, index) => {
                                    return (
                                      <Resultss
                                        req={req}
                                        data={groupedDate[req]}
                                        index={index}
                                        setSelectedDriver={setSelectedDriver}
                                        selectedDriver={selectedDriver}
                                        driversList={driversList}
                                        handleShowDrop={handleShowDrop}
                                        assignDriver={assignDriver}
                                      />
                                    );
                                  })}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {(checked1 && dropOff.length > 0) && (
                  <div>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount1}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={6}
                      onPageChange={handlePageClick1}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
                {(checked2 && dropOff.length > 0) && (
                  <div>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount2}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={6}
                      onPageChange={handlePageClick2}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
                {(checked3 && dropOff.length > 0) && (
                  <div>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount3}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={6}
                      onPageChange={handlePageClick3}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
                {(checked4 && dropOff.length > 0) && (
                  <div>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount4}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={6}
                      onPageChange={handlePageClick4}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <PickUpModal showDrop={showDrop} handleCloseDrop={handleCloseDrop} />
    </main>
  );
};

export default Pick;
