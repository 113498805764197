import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import FilterModal from "./modal/filter";
import Pusher from "pusher-js";
import moment from "moment";
import Resultss from "./Result";
import { Spinner } from "react-bootstrap";

const CollectionNotification = ({ setTitleName }) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  const handleShowFilter = () => setShowFilter(true);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [groupedDate, setGroupedDate] = useState({});
  const othersObj = {};

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  // console.log(currentDate);

  const getNotification = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let payload = {
      start_date: "",
      end_date: "",
    };
    axios
      .post(`${switchEndpoint}/collection-center/notifications`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log("this is the notification endpoint >>>>>", response.data);
        const results = response.data;
        setInfo(results);
        results.forEach((element) => {
          let dateFormated = moment(element.created_at).format("DD/MM/YYYY");
          const created_at = dateFormated;
          if (created_at in othersObj) {
            othersObj[created_at] = [...othersObj[created_at], element];
          } else {
            othersObj[created_at] = [element];
          }
        });
        // console.log(othersObj)
        setGroupedDate(othersObj);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const createNotification = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const center = localStorage.getItem("center");
    axios
      .post(
        `${switchEndpoint}/test-center-notify`,
        {
          message: `${center} just dropped a package`,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // console.log("this is the response >>>>>", response.data);
        const results = response.data;
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const filterNotification = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let payload = {
      start_date: start,
      end_date: end,
    };
    // console.log(payload)
    axios
      .post(`${switchEndpoint}/collection-center/notifications`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log("this is the notification endpoint >>>>>", response.data);
        const results = response.data;
        setInfo(results);
        results.forEach((element) => {
          let dateFormated = moment(element.created_at).format("DD/MM/YYYY");
          const created_at = dateFormated;
          if (created_at in othersObj) {
            othersObj[created_at] = [...othersObj[created_at], element];
          } else {
            othersObj[created_at] = [element];
          }
        });
        // console.log(othersObj)
        setGroupedDate(othersObj);
        setLoading(false);
        handleCloseFilter();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    setTitleName("Notification");
    getNotification();

    // Pusher.logToConsole = true;
    // const pusher = new Pusher("f0b74736a401e2a1aa22", {
    //   cluster: "mt1",
    // });
    // const channel1 = pusher.subscribe("collection-center-1");
    // channel1.bind("collection-center-notification", function (data) {
    // alert(data.data.message);
    // console.log(data);
    // });
    // createNotification();
    // return () => {
    //   pusher.unsubscribe("collection-center-1");
    // };
  }, []);
  return (
    <main>
      <div className="p-3">
        <div className="cardss mb-3">
          <div className="px-4">
            <div className="d-flex justify-content-between align-items-center py-3">
              <div></div>
              {/* <div className="">
                <p>Today</p>
              </div> */}
              <div>
                <p
                  style={{
                    color: "#231F20",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                  onClick={handleShowFilter}
                >
                  Filter By Date
                  <span style={{ color: "#c4da00", marginLeft: "5px" }}>
                    &#62;
                  </span>
                </p>
              </div>
            </div>

            <div className="mt-3">
              {loading ? (
                <div className="card_inners_amount mt-4 text-center">
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                </div>
              ) : (
                <div>
                  {!Object.keys(groupedDate).length ? (
                    <p className="text-center">No Data Available</p>
                  ) : (
                    <div>
                      {Object.keys(groupedDate).length &&
                        Object.keys(groupedDate).map((req, index) => {
                          return (
                            <Resultss
                              req={req}
                              data={groupedDate[req]}
                              index={index}
                            />
                          );
                        })}
                    </div>
                  )}
                </div>
              )}{" "}
            </div>
          </div>
        </div>
      </div>
      <FilterModal
        showFilter={showFilter}
        handleCloseFilter={handleCloseFilter}
        start={start}
        end={end}
        setStart={setStart}
        setEnd={setEnd}
        filterNotification={filterNotification}
        loading={loading}
      />
    </main>
  );
};

export default CollectionNotification;
