import React, { useState, useEffect } from "react";
import "./index.css";
import newLogo from "../../assets/images/newLogo.png";
import OtpInput from "react-otp-input";
import axios from "axios";
import { toast } from "react-toastify";
import { switchEndpoint } from "../../redux/actions/endpoints";

const DeleteAccountOTP = (props) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const phoneNum = localStorage.getItem("phoneNumber");
    setPhone(phoneNum);
  }, []);

  const handleFinalDelete = () => {
    setLoading(true);
    let payload = {
      otp: otp,
      phone: phone,
    };
    axios
      .post(`${switchEndpoint}/public/user/validate-delete`, payload)
      .then((response) => {
        // console.log(response.data);
        localStorage.removeItem("phoneNumber");
        setLoading(false);
        toast.success("Account deleted successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        props.history.push("/delete-account-final");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleOTPVerification = () => {
    setLoading(true);
    let payload = {
      otp: otp,
      phone: phone,
    };
    axios
      .post(`${switchEndpoint}/public/user/confirm-delete-otp`, payload)
      .then((response) => {
        // console.log(response.data);
        setLoading(false);
        toast.success("OTP validated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        handleFinalDelete();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  //   const handleDeleteAccount = () => {
  //     if (!phone) {
  //       toast.error("Phone Number not provided !", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         pauseOnHover: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //       props.history.push("/delete-account");
  //       return;
  //     } else {
  //       setLoading(true);
  //       axios
  //         .post(`${switchEndpoint}/public/user/initiate-delete`, {
  //           phone: phone,
  //         })
  //         .then((response) => {
  //           setLoading(false);
  //           toast.success("OTP generated successfully", {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             pauseOnHover: true,
  //             progress: undefined,
  //             theme: "light",
  //           });
  //         })
  //         .catch((error) => {
  //           setLoading(false);
  //           toast.error(error?.response?.data?.message || error.message, {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             pauseOnHover: true,
  //             progress: undefined,
  //             theme: "light",
  //           });
  //         });
  //     }
  //   };
  return (
    <div>
      <div className="row noMarginPadding">
        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8 col-xs-12 PaddingLeft-10">
          <div className="container pl-4 pt-4 PaddingLeft-10">
            <div style={{ width: "150px" }}>
              <img src={newLogo} alt="logo" style={{ width: "100%" }} />
            </div>
            <div className="w-50 ml-5 mt-5 containerWidth">
              <h5 className="headerText headerh5">Delete Account</h5>
              <p className="loginStyle mb-0">Enter OTP</p>
              <p className="pin">Enter the 6 digit PIN sent to your email.</p>

              <div>
                <OtpInput
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                  numInputs={6}
                  className="text-center otp-inputs"
                />
              </div>

              <div className="validateOtp">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block mt-4"
                  onClick={handleOTPVerification}
                  disabled={loading}
                >
                  Validate OTP
                </button>
              </div>

              <div className="resend">
                <p
                  style={{
                    color: "#231F20",
                    fontSize: "13px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {}}
                >
                  Didn’t receive the code? Resend OTP &nbsp;
                  <span style={{ color: "#c4da00" }}>&#62;</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-4 rightImg"></div>
      </div>
    </div>
  );
};

export default DeleteAccountOTP;
