import React, { useState, useEffect } from "react";
import "./index.css";
import avatar from "../../../../../assets/images/avatarr.png";
import dropOff from "../../../../../assets/images/dropOfff.png";
import pick from "../../../../../assets/images/pick.png";
import mapp from "../../../../../assets/images/bigMap.png";
import DriversDetailsModal from "./modals/details";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import MapsUI from "./map";
import TestComponent from "./test";

const DriversAggregation = ({ setTitleName }) => {
  const [loading, setLoading] = useState(false);
  const [activeDrivers, setActiveDrivers] = useState([]);
  const [inactiveDrivers, setInactiveDrivers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [coord, setCoord] = useState({
    long: 0,
    lat: 0,
  });

  const [showDrop, setShowDrop] = useState(false);
  const handleCloseDrop = () => setShowDrop(false);
  const handleShowDrop = () => setShowDrop(true);

  const getDrivers = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/drivers`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        const results = response.data;
        localStorage.setItem("driversProps", JSON.stringify(results));
        setDrivers(response.data);
        let active = response.data.filter(
          // (x) => x.availability_status === "active"
          (x) => x.last_login_status === "active"
        );
        let inactive = response.data.filter(
          // (x) => x.availability_status === "inactive"
          (x) => x.last_login_status === "inactive"
        );
        setActiveDrivers(active);
        setInactiveDrivers(inactive);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    setTitleName("Drivers");
    getDrivers();
  }, []);
  return (
    <main>
      <div className="main__container">
        <div className="separatingDiv">
          <div>
            <div className="requests">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {" "}
                  <h4 className="dropOff__title mt-1">Active Drivers</h4>
                </div>
                <div>
                  {loading ? (
                    ""
                  ) : (
                    <p
                      className="dropOff__title mt-1"
                      style={{ fontSize: "13px" }}
                    >
                      {`${activeDrivers.length} / ${drivers.length} Drivers Active`}
                    </p>
                  )}
                </div>
              </div>

              <div className="cardss">
                <div className="requestFlex mb-3">
                  <div className="d-flex align-items-center"></div>
                  <div></div>
                </div>
                <div>
                  <div>
                    {loading ? (
                      <div className="card_inners_amount mt-2 text-center">
                        <span>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                    ) : (
                      <div>
                        {activeDrivers.map((req) => {
                          return (
                            <div key={req.id}>
                              <div className="reqBg">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>
                                    <div className="d-flex align-items-center">
                                      <img src={avatar} alt={avatar} />
                                      <p className="mb-0 ml-2">{req.name}</p>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    {/* <div className="d-flex">
                                      <p
                                        className="mb-0 mr-5"
                                        style={{ fontSize: "13px" }}
                                      >
                                        N5
                                      </p>
                                    </div> */}
                                    <div className="ml-2">
                                      <div
                                        className="buttonLike"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          localStorage.setItem(
                                            "activeDrivers",
                                            JSON.stringify(req)
                                          );
                                          handleShowDrop();
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "#fff",
                                            textAlign: "center",
                                            margin: "auto",
                                          }}
                                        >
                                          &#62;
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="requests">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="dropOff__title mt-1">Inactive Drivers</h4>
                </div>
                <div>
                  {loading ? (
                    ""
                  ) : (
                    <p
                      className="dropOff__title mt-1"
                      style={{ fontSize: "13px" }}
                    >
                      {`${inactiveDrivers.length} / ${drivers.length} Drivers Inactive`}
                    </p>
                  )}
                </div>
              </div>

              <div className="cardss">
                <div className="requestFlex mb-3">
                  <div className="d-flex align-items-center"></div>
                  <div></div>
                </div>
                <div>
                  <div>
                    {loading ? (
                      <div className="card_inners_amount mt-2 text-center">
                        <span>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                    ) : (
                      <div>
                        {inactiveDrivers.map((req) => {
                          return (
                            <div key={req.id}>
                              <div className="reqBg">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>
                                    <div className="d-flex align-items-center">
                                      <img src={avatar} alt={avatar} />
                                      <p className="mb-0 ml-2">{req.name}</p>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    {/* <div className="d-flex">
                                      <p
                                        className="mb-0 mr-5"
                                        style={{ fontSize: "13px" }}
                                      >
                                        N5
                                      </p>
                                    </div> */}
                                    <div className="ml-2">
                                      <div
                                        className="buttonLike"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          localStorage.setItem(
                                            "activeDrivers",
                                            JSON.stringify(req)
                                          );
                                          handleShowDrop();
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "#fff",
                                            textAlign: "center",
                                            margin: "auto",
                                          }}
                                        >
                                          &#62;
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <div className="requests">
              <div className="mapImage ml-4">
                <TestComponent drivers={drivers} /> */}
          {/* <MapsUI coord={coord} /> */}
          {/* </div>
            </div>
          </div> */}
        </div>
      </div>
      <DriversDetailsModal
        showDrop={showDrop}
        handleCloseDrop={handleCloseDrop}
        setCoord={setCoord}
      />
    </main>
  );
};

export default DriversAggregation;
