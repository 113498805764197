import React from "react";
import navLogo from "../assets/images/newLogo.png";

const PrivacyPolicy = () => {
    return (
        <div className="p-5 policy bgImage">
            <div className="background-overlay"></div>
            <header className="static-header px-5">
                <div className="d-flex justify-content-between align-items-center px-5 logoHead">
                    <div />
                    <div>
                        <img
                            src={navLogo}
                            alt="nav logo"
                            style={{ width: "100%", height: "50px" }}
                        />
                    </div>
                </div>

            </header>
            <div className="content">
                <div>
                    <h6 className="text-center boldHeader">PRIVACY POLICY</h6>
                    <div className="px-5">
                        <h6 className="text-left boldHeader">1. Privacy</h6>
                        <p className="headParagraphText">
                            Switch Recycling Innovations. (“us,” “we,” “our”, or “Switch”)
                            respects the privacy rights of our customers, visitors, and other
                            users of our recycling information services (collectively,
                            “Services”) provided through our website, web Mobile Application,
                            and any Mobile Application software for any platform (iPhone,
                            Android, etc.) that you may download (collectively, the
                            “Software”). We created this Software privacy policy (“Privacy
                            Policy”) to give you confidence as you use the Software and to
                            demonstrate our commitment to the protection of any personal
                            information we hold.
                        </p>
                        <p className="headParagraphText">
                            Switch Recycling Innovations is responsible for the personal
                            information we collect and hold. To ensure this accountability, we
                            have developed this Privacy Policy and trained our employees about
                            our policies and practices.
                        </p>
                        <p className="headParagraphText">
                            This Privacy Policy is only applicable to the Software. Our
                            website may contain links to other websites and/or may be
                            integrated with other software created by third parties. Those
                            sites or software components are not governed by this Privacy
                            Policy. Similarly, our Software may be embedded in other
                            third-party websites that are not administered by us, and those
                            sites are not governed by this Privacy Policy. Your use of the
                            Software is governed by this Privacy Policy and the Terms of
                            Agreement.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">2. Software Data Collected</h6>
                        <p className="headParagraphText">
                            We automatically track and collect the following categories of
                            information when you use our Software: (1) IP (which stands for
                            Internet Protocol) addresses; (2) domain servers; (3) types of
                            computers Accessing the Software; (4) types of web browsers used
                            to Switch the Software; (5) referring source which may have sent
                            you to the Software; and (6) other information associated with the
                            interaction between your browser and the Software (collectively,
                            “Software Data”).
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">
                            3. Personal Information Collected
                        </h6>
                        <p className="headParagraphText">
                            We provide our Services through our Software. For you to Switch
                            certain features of the Software, we require you to provide us
                            with certain personal information that identifies you, or from
                            which your identity could be deduced (“Personal Information”).
                            Personal Information includes (1) Contact Data (such as your
                            e-mail address and Switch password); (2) Demographic Data (such as
                            your gender and your age); and (3)
                        </p>
                        <p className="headParagraphText">
                            Location Data (such as your address or zip/postal code). If you
                            communicate with us by, for example, e- mail or letter, any
                            information provided in such communication may be collected as
                            Personal Information. If we did not collect and use your Personal
                            Information, we could not provide you with our Services.
                        </p>
                        <p className="headParagraphText">
                            You may choose not to provide us with any Personal Information. In
                            such an event, you can still Switch and use much of the Software;
                            however, you will not be able to Switch and use those portions of
                            the Software that require your Personal Information.
                        </p>
                        <p className="headParagraphText">
                            If you access any Services requiring a username and password, you
                            are solely responsible for keeping such items strictly
                            confidential.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">4. Cookies</h6>
                        <p className="headParagraphText">
                            “Cookies” are computer files that are placed on your computer by a
                            website. In these files, various pieces of information can be
                            stored, from user identification and preferences to activities
                            conducted while browsing the website. You may block cookies or
                            delete cookies from your computer if you wish but must do so at
                            your own cost and responsibility. If you do block or disable
                            cookies, and you are accessing the Software through a website,
                            then you may not have to Switch to the entire set of features of
                            our Software.
                        </p>
                        <p className="headParagraphText">
                            Generally, we use “cookies” to customize your experience on our
                            website and to store your password so you do not have to re-enter
                            it each time you visit the website. We do not link the information
                            stored in these cookies directly to any of the Personal
                            Information you submit while using the Software.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">5. Storage of Information</h6>
                        <p className="headParagraphText">
                            We store all Software Data and review postings indefinitely, even
                            after they are deleted, and may retain such information elsewhere.
                            We store all Personal Information until you request that we amend
                            or delete it, in which case we may still end up retaining some of
                            that information for the reasons described in Section 10 below.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">
                            6. Personal Information Protection and Electronic Documents Act
                        </h6>
                        <p className="headParagraphText">
                            From January 1, 2004, all businesses engaged in commercial
                            activities must comply with the Personal Information Protection
                            and Electronic Documents Act (“PIPEDA”) and the other local laws
                            for the Protection of Personal Information that it incorporates.
                            PIPEDA gives you rights concerning the privacy of your personal
                            information. If you are located outside of Nigeria, you may have
                            local laws which protect the collection and use of your personal
                            information in your Country.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">
                            7. Switch’s Use of Your Information
                        </h6>
                        <p className="headParagraphText">
                            We may use your Contact Data to send you information about Switch
                            or our products or Services, or to contact you when required,
                            including to advise you about changes to your recycling
                            information. We may use your Personal Information to customize
                            your experience on the Software, in e-mails, and in other
                            communications, displaying content that we think is appropriate
                            for you. You may unsubscribe from receiving further emails from us
                            at any time by sending an email to support@switchrecycling.com
                            with the word “Unsubscribe” in the subject line.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">8. Information Sharing</h6>
                        <p className="headParagraphText">
                            We share certain categories of information we collect from you in
                            the ways described in this Privacy Policy. We may share
                            Demographic Data and Location Data with other third parties only
                            on an aggregate (i.e., non-personally identifiable) basis.
                        </p>
                        <p className="headParagraphText">
                            We may occasionally provide the Services in partnership with your
                            local government. By using the Software and Services and providing
                            us with Personal Information, you consent to our sharing of
                            Demographic Data and Location Data with your local government.
                        </p>
                        <p className="headParagraphText">
                            We also share Personal Information with our business partners who
                            assist us by performing core services related to our operation of
                            the Software. Those business partners shall be bound to uphold the
                            same standards of security and confidentiality that we have
                            promised to you in this Privacy Policy, and they will only use
                            your Personal Information to carry out their specific business
                            obligations to Switch and to provide your requested services. We
                            may also transfer information about you to third parties in
                            connection with a merger, sale or acquisition by or of Switch.
                        </p>
                        <p className="headParagraphText">
                            We may share your Contact Data, Demographic Data, and Location
                            Data with third-party service providers to facilitate the seamless
                            integration of different software components. For example, we may
                            share your username and password information with such third-party
                            software providers to avoid the need for you to log in to multiple
                            accounts in the course of using a single website or Mobile
                            Application. In some cases, third-party software providers may
                            have provided us with Personal Information that you shared with
                            them, also to facilitate seamless integration of different
                            software components.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">9. Confidentiality</h6>
                        <p className="headParagraphText">
                            Except as otherwise provided in this Privacy Policy, we will keep
                            your Personal Information private and will not share it with third
                            parties, unless we believe in good faith that disclosure of your
                            Personal Information or any other information we collect about you
                            is necessary to (1) comply with a court order or other legal
                            process; (2) protect the rights, property or safety of Switch or
                            another party; (3) enforce our Terms of Agreement, or (4) respond
                            to claims that any posting or other content violates the rights of
                            third- parties.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">
                            10. Controlling and Correcting Your Personal Information
                        </h6>
                        <p className="headParagraphText">
                            Since we use your Personal Information to provide Services to you,
                            your Personal Information must be accurate and up-to-date. If any
                            of your Personal Information changes, please advise us so that we
                            can make any necessary changes (you can either write to us
                            directly or, if you are a registered user of our Software, you can
                            modify some of the Personal Information you have included in your
                            profile).
                        </p>
                        <p className="headParagraphText">
                            Upon your written request, we will use commercially reasonable
                            efforts to delete your account and the Personal Information in
                            your profile; however, it may not be possible to remove your
                            account without some residual information being retained by us.
                        </p>
                        <p className="headParagraphText">
                            If we hold Personal Information about you and you can establish
                            that it is not accurate, complete, and up- to-date, we will take
                            reasonable steps to correct it.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">
                            11. Switch to Your Personal Information
                        </h6>
                        <p className="headParagraphText">
                            You may ask to Switch to any Personal Information we hold about
                            you. Summary information is available on request. More detailed
                            requests that require archive or other retrieval costs may be
                            subject to our normal professional and disbursement fees.
                        </p>
                        <p className="headParagraphText">
                            Your rights to access your Personal Information are not absolute.
                            We may deny Switch when:
                        </p>
                        <p className="headParagraphText ml-3">
                            (i) Denial of Switch is required or authorized by law; Information
                            relates to existing or anticipated legal proceedings against you;
                        </p>
                        <p className="headParagraphText ml-3">
                            (ii) When granting you Switch would have an unreasonable impact on
                            other people’s privacy;
                        </p>
                        <p className="headParagraphText ml-3">
                            (iii)When to do so would prejudice negotiations with you;
                        </p>
                        <p className="headParagraphText ml-3">
                            (iv)To protect our rights and property; or
                        </p>
                        <p className="headParagraphText ml-3">
                            (v) Where the request is frivolous or vexatious.
                        </p>
                        <p className="headParagraphText">
                            If we deny your request for Switch or refuse a request to correct
                            your Personal Information, we shall explain why.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">12. Security</h6>
                        <p className="headParagraphText">
                            The security of your Personal Information is important to us. We
                            take reasonable precautions to ensure that your Personal
                            Information is kept safe from loss, unauthorized Switch,
                            modification, or disclosure. Among the steps taken to protect your
                            Personal Information are:
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">Premises security;</h6>
                        <p className="headParagraphText">
                            Restricted file Switch to personal information;
                        </p>
                        <p className="headParagraphText">
                            Deploying technological safeguards like encryption and security
                            software and firewalls to prevent hacking or unauthorized computer
                            Switch; and
                        </p>
                        <p className="headParagraphText">
                            Internal password and security policies.
                        </p>
                        <p className="headParagraphText">
                            “Phishing” is a fraudulent practice designed to steal your
                            Personal Information. If you receive an e-mail that looks like it
                            is from Switch asking you for your Personal Information, do not
                            respond. We will never request your password, user name, credit
                            card information, or other Personal Information through e-mail.
                        </p>
                        <p className="headParagraphText">
                            A website or other Mobile Application cannot be completely
                            absolutely protected against intentional or malicious intrusion
                            attempts. Further, we do not control the devices or computers or
                            the internet over which you may choose to send Personal
                            Information and cannot, therefore, prevent such interceptions or
                            compromises to your Personal Information while in transit to us.
                            If we learn that your Personal Information has been disclosed in a
                            manner that is not by this Privacy Policy, we will use reasonable
                            efforts to advise you of the disclosure as soon as reasonably
                            possible.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">
                            13. Lost or Stolen Information
                        </h6>
                        <p className="headParagraphText">
                            You must promptly notify us if your Contact Data is lost, stolen,
                            or used without your permission. In such an event, we will remove
                            that Contact Data from your account and update our records
                            accordingly.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">
                            14. Integration with Other Software
                        </h6>
                        <p className="headParagraphText">
                            Our Software contains links to third-party websites and/or is
                            integrated with the software of third-party providers with whom
                            Switch has no affiliation. Except as disclosed in this Privacy
                            Policy, we do not share your personal information with those
                            service providers and we are not responsible for their privacy
                            practices. Some websites and software may be similar to our
                            Software. Please be cautious that you may be using a third party’s
                            software and that this Privacy Policy only covers our Software.
                            Should you decide to visit one of these third-party websites or
                            use their software, we recommend that you read that provider’s
                            privacy policy.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">
                            15. Updates and Changes to Privacy Policy
                        </h6>
                        <p className="headParagraphText">
                            Since we regularly review all of our policies and procedures, we
                            may change our Privacy Policy from time to time. All changes will
                            be posted on our website. In all cases, the use of the information
                            we collect is subject to the Privacy Policy in effect at the time
                            such information is collected.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">16. E-mail</h6>
                        <p className="headParagraphText">
                            You should be aware that e-mail is not an entirely secure medium.
                            You should be aware of this when using e-mail to send or receive
                            personal or confidential information
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">
                            17. Limitation of Liability
                        </h6>
                        <p className="headParagraphText">
                            We make no guarantee as to the security, integrity, or
                            confidentiality of any information transmitted to or from our
                            Software, or stored within our Software. Beyond our reasonable
                            care to safeguard your information while in transit, Switch does
                            not guarantee the absolute security of electronic communications
                            or transmissions since any transmission made over the internet by
                            anyone individual runs the risk of interception.
                        </p>
                        <p className="headParagraphText">
                            You assume the sole risk of transmitting your Personal Information
                            as it relates to the use of our Software, and for any data
                            corruptions, intentional interceptions, intrusions or unauthorized
                            Switch to Personal Information, or of any delays, interruptions to
                            or failures preventing the use of our Software. In no event shall
                            we be liable for any direct, indirect, special, consequential or
                            monetary damages, including fees and penalties in connection with
                            your use of the Services provided by our Software or connectivity
                            to or from this site to any other site.
                        </p>
                        <p className="headParagraphText">
                            If your non-identifiable data is used for research purposes, then
                            the limitation of liability provision set forth above is waived
                            concerning any harms suffered or liabilities incurred as a result
                            of any research activities.
                        </p>
                    </div>
                </div>
                <div>
                    <h6 className="text-center boldHeader">DATA CONSENT</h6>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">HOW WE PROCESS YOUR DATA</h6>
                        <p className="headParagraphText">
                            From time to time, you will be asked to provide personal
                            information about yourself (e.g., name and email address etc) to
                            receive or use services on our website. In addition, we
                            automatically collect certain data which we outline below. Some of
                            this information is stored in cookies, you can find out how we use
                            these in our cookies policy.
                        </p>
                        <p className="headParagraphText">
                            We process your data in these ways:
                        </p>
                        <p className="headParagraphText ml-3">
                            I. For certain legitimate interests:
                        </p>
                        <p className="headParagraphText ml-5">
                            a. To improve your experience
                        </p>
                        <p className="headParagraphText ml-5">
                            b. To allow you to sign in and stay signed in on our site
                        </p>
                        <p className="headParagraphText ml-5">
                            c. To display content suitable to your device
                        </p>
                        <p className="headParagraphText ml-5">
                            d. To measure the effectiveness of our website and marketing
                            channels;
                        </p>
                        <p className="headParagraphText ml-5">
                            e. To determine the effectiveness of resources e.g., to provide a
                            follow-up email after a user has downloaded a specific resource to
                            understand the business change that may have happened.
                        </p>
                        <p className="headParagraphText ml-5">
                            f. To identify and prevent fraud.
                        </p>
                        <p className="headParagraphText ml-5">
                            g. To identify and prevent spam; and
                        </p>
                        <p className="headParagraphText ml-5">
                            h. If you are one of our partners, to send you email marketing
                            communications.
                        </p>

                        <p className="headParagraphText ml-3">II. Based on your consent:</p>
                        <p className="headParagraphText ml-5">
                            a. To provide you with a personalized experience i.e., providing
                            content tailored to you.
                        </p>
                        <p className="headParagraphText ml-5">
                            b. To send you marketing communications i.e., email marketing and
                            online advertising, for which we will gain separate consent
                        </p>
                        <p className="headParagraphText ml-5">
                            c. To measure the effectiveness of our audience profiles on our
                            website and marketing channels
                        </p>
                        <p className="headParagraphText ml-5">
                            d. For research purposes and to comply with a legal obligation.
                        </p>
                    </div>

                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">WHAT DATA DO WE COLLECT</h6>
                        <p className="headParagraphText">The data we collect includes:</p>
                        <p className="headParagraphText ml-3">
                            i. Name, email address, postcode, country and telephone number
                        </p>
                        <p className="headParagraphText ml-3">
                            ii. Organization and job title
                        </p>
                        <p className="headParagraphText ml-3">iii. IP address</p>
                        <p className="headParagraphText ml-3">
                            iv. Website actions (i.e., content viewed and downloaded)
                        </p>
                        <p className="headParagraphText ml-3">v. Marketing preferences</p>
                        <p className="headParagraphText ml-3">vi. Audience profiling</p>
                        <p className="headParagraphText">
                            Information is specific to an event i.e., dietary needs. The
                            information will only be used for the event.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">SHARING YOUR DATA</h6>
                        <p className="headParagraphText">
                            Subject to applicable data protection law, we may share your data
                            with:
                        </p>
                        <p className="headParagraphText ml-3">
                            i. Companies who provide services to us
                        </p>
                        <p className="headParagraphText ml-3">
                            ii. Legal and professional advisors or bodies
                        </p>
                        <p className="headParagraphText ml-3">
                            iii. Fraud prevention companies
                        </p>
                        <p className="headParagraphText ml-3">
                            iv. An organization we sell or license the relevant part of the
                            business to retain your legitimate interests.
                        </p>
                        <p className="headParagraphText ml-3">
                            v. Any other organization where we have gained your consent or
                            where it is required by law.
                        </p>
                        <p className="headParagraphText">
                            Whenever we process data for these purposes, we will ensure that
                            we always keep your Data rights in high regard and take account of
                            these rights. You have the right to object to this processing if
                            you wish, and if you wish to do so, please contact us.
                        </p>
                    </div>
                    <div className="px-5 pt-2">
                        <h6 className="text-left boldHeader">
                            HOW LONG DO WE KEEP YOUR DATA
                        </h6>
                        <h6 className="text-left boldHeader">Website accounts:</h6>
                        <p className="headParagraphText">
                            We remove accounts that have been inactive for 2 years
                        </p>
                        <h6 className="text-left boldHeader">
                            Web Mobile Application accounts:
                        </h6>
                        <div className="ml-5">
                            <ul>
                                <li>
                                    <p className="headParagraphText">
                                        Where we can identify when someone last signed in, we remove
                                        accounts that have been inactive for 4 years
                                    </p>
                                </li>
                                <li>
                                    <p className="headParagraphText">
                                        Where we can’t identify when someone last signed in, we will
                                        retain the information for the lifetime of the Mobile
                                        Application to retain your legitimate interests in using the
                                        Mobile Application.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <h6 className="text-left boldHeader">Google Analytics;</h6>
                        <p className="headParagraphText">
                            User-level and event-level data stored by Google Analytics is
                            automatically deleted from Analytics’ servers after 50 months.
                        </p>
                        <h6 className="text-left boldHeader">Switch Analytics;</h6>
                        <p className="headParagraphText">
                            User-level data stored by the Switch analytics tool will be
                            deleted or fully anonymised after 50 months.
                        </p>
                        <h6 className="text-left boldHeader">Email marketing lists;</h6>
                        <p className="headParagraphText">
                            You have the option to unsubscribe from one of our email marketing
                            lists, and we will always honour this. We will also periodically
                            clean our lists to ensure they are up-to-date.
                        </p>
                        <h6 className="text-left boldHeader">
                            YOUR RIGHTS AS A DATA SUBJECT;
                        </h6>
                        <p className="headParagraphText">
                            At any point while we have or processing your data, you, the data
                            subject, have the following rights:
                        </p>
                        <p className="headParagraphText ml-3">
                            i. Right of Switch – you have the right to request a copy of the
                            information that we hold about you.
                        </p>
                        <p className="headParagraphText ml-3">
                            ii. Right of rectification – you have a right to correct data that
                            we hold about you that is inaccurate or incomplete.
                        </p>
                        <p className="headParagraphText ml-3">
                            iii. Right to be forgotten – in certain circumstances, you can ask
                            for the data we hold about you to be erased from our records.
                        </p>
                        <p className="headParagraphText ml-3">
                            iv. Right to restriction of processing – where certain conditions
                            apply to have a right to restrict the processing.
                        </p>
                        <p className="headParagraphText ml-3">
                            v. Right of portability – you have the right to have the data we
                            hold about you transferred to another organization.
                        </p>
                        <p className="headParagraphText ml-3">
                            vi. Right to object – you have the right to object to certain
                            types of processing such as direct marketing.
                        </p>
                        <p className="headParagraphText ml-3">
                            vii. Right to object to automated processing, including profiling
                            – you also have the right to be subject to the legal effects of
                            automated processing or profiling.
                        </p>

                        <h6 className="text-left boldHeader">
                            Switch to your personal information
                        </h6>
                        <p className="headParagraphText">
                            In line with the above rights, you have the right to request a
                            copy of the personal information SWITCH holds about you, to have
                            any inaccuracies corrected, or to request that it be deleted.
                            Please address requests to the person responsible for Data
                            Protection.
                        </p>

                        <h6 className="text-left boldHeader">Users under 18</h6>
                        <p className="headParagraphText">
                            If you are under 18, please get your parent/guardian's permission
                            beforehand whenever you provide personal information to SWITCH.
                            Users without this consent are not allowed to provide us with
                            personal information.
                        </p>

                        <h6 className="text-left boldHeader">Contact us</h6>
                        <p className="headParagraphText">
                            If you have any questions about this Privacy Policy or you wish to
                            make a complaint about how your data is being processed by us, or
                            how your complaint has been handled, you have the right to
                            complain directly to the Supervisory Authority and SWITCH’s data
                            protection representative.
                        </p>
                        <p className="headParagraphText">The details for the latter are:</p>

                        <h6 className="text-left boldHeader">
                            SWITCH RECYCLING INNOVATIONS.
                        </h6>
                        <p className="headParagraphText">29b Adediran Ajao Crescent,</p>
                        <p className="headParagraphText">
                            Anthony Village, Kosofe, Lagos State.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
