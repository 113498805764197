import React, { useState, useEffect } from "react";
import "./index.css";
import avatar from "../../../../../assets/images/avatarr.png";
import weigh from "../../../../../assets/images/wweigh.png";
import Spinner from "react-bootstrap/Spinner";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Table } from "antd";
import { BsThreeDotsVertical, BsTruckFlatbed } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import EnterCodeModal from "./collections/enterDropOff";
import CompleteModal from "./collections/completeModal";
import axios from "axios";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import { toast } from "react-toastify";
import Result from "./Result";
import AuthenticateModal from "./collections/authenticate";
import moment from "moment";

const CollectionCenter = ({ setTitleName }) => {
  const [dropOff, setDropOff] = useState("");
  const [password, setPassword] = useState("");
  const [tonnes, setTonens] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [droppData, setDroppData] = useState([]);
  const [dropOffs, setDropOffs] = useState([]);
  const [dropOfff, setDropOfff] = useState("");
  const [groupedDate, setGroupedDate] = useState({});
  const [loading, setLoading] = useState(false);
  const othersObj = {};

  const history = useHistory();

  const [showCode, setShowCode] = useState(false);
  const handleCloseCode = () => setShowCode(false);
  const handleShowCode = () => setShowCode(true);

  const [showComplete, setShowComplete] = useState(false);
  const handleCloseComplete = () => setShowComplete(false);
  const handleShowComplete = () => setShowComplete(true);

  const [showAuthenticate, setShowAuthenticate] = useState(false);
  const handleCloseAuthenticate = () => setShowAuthenticate(false);
  const handleShowAuthenticate = () => setShowAuthenticate(true);

  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      key: "id",
      sorter: false,
      render: (id, record, index) => {
        ++index;
        return index;
      },
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      render: (text) => (
        <p className="mb-0" style={{ fontSize: "13px" }}>{`${text}kg`}</p>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => {
        const capitalized = type.charAt(0).toUpperCase() + type.slice(1);
        return (
          <p className="mb-0" style={{ fontSize: "13px" }}>
            {capitalized}
          </p>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => <a>{moment(date).format("DD/MM/YYYY")}</a>,
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const capitalized = status.charAt(0).toUpperCase() + status.slice(1);
        return (
          <p className="mb-0" style={{ color: "#c4da00", fontSize: "13px" }}>
            {capitalized}
          </p>
        );
      },
    },

    {
      title: " ",
      key: "action",
      render: () => <BsThreeDotsVertical style={{ cursor: "pointer" }} />,
    },
  ];

  const data = [
    {
      key: "1",
      weight: "54.49kg",
      type: "Drop Off",
      date: "29/10/22",
      time: "16:32",
      code: "922 419",
      status: (
        <p className="mb-0" style={{ color: "#c4da00", fontSize: "13px" }}>
          Completed
        </p>
      ),
    },
    {
      key: "2",
      weight: "54.49kg",
      type: "Drop Off",
      date: "29/10/22",
      time: "16:32",
      code: "922 419",
      status: (
        <p className="mb-0" style={{ color: "#c4da00", fontSize: "13px" }}>
          Completed
        </p>
      ),
    },
    {
      key: "3",
      weight: "54.39kg",
      type: "Drop Off",
      date: "29/10/22",
      time: "16:32",
      code: "922 419",
      status: (
        <p className="mb-0" style={{ color: "#c4da00", fontSize: "13px" }}>
          Completed
        </p>
      ),
    },
  ];

  const getAggregationDropOff = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/collection-center-dropoffs`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        setDropOffs(response.data.data);
        const result = response.data.data;
        result.forEach((element) => {
          const date_type = element.date_type;
          if (date_type in othersObj) {
            othersObj[date_type] = [...othersObj[date_type], element];
          } else {
            othersObj[date_type] = [element];
          }
        });
        setGroupedDate(othersObj);
        // setTonens(response.data);
      })
      .catch((error) => {
        toast.error(error.response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getAggregationStat = () => {
    setLoading(BsTruckFlatbed)
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/collection-center-dropoffs/stats`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // console.log(response.data);
        setLoading(false)
        setTonens(response.data);

      })
      .catch((error) => {
        toast.error(error.response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false)
      });
  };

  const getHistory = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/collection-center-dropoffs/history`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // console.log(response.data);
        setHistoryData(response.data);
      })
      .catch((error) => {
        toast.error(error.response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const getSingleDropOff = (code) => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/aggregation-center/single-dropoff/${code}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        setDroppData(response.data.data);
        handleShowComplete();
      })
      .catch((error) => {
        toast.error(error.response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const completeDropOff = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      code: droppData.code,
      weight: Number(dropOfff),
      password: password,
    };
    console.log(payload);
    axios
      .post(
        `${switchEndpoint}/aggregation-center/collection-center-dropoffs/process`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // console.log(response.data);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        handleCloseAuthenticate();
        setPassword("");
        setDropOfff("");
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response.data);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    setTitleName("Collection Center");
    getAggregationStat();
    getAggregationDropOff();
    getHistory();
  }, [Object.keys(groupedDate).length]);

  useEffect(() => {
    if (Object.keys(groupedDate).length >= 1) {
      getAggregationDropOff();
    }
  }, []);

  return (
    <main>
      {/* <Collectionn /> */}
      <div className="main__container">
        <div className="separatingDivs">
          <div className="main__cardss">
            <div className="cardsss cards_extra">
              <div className="card_inners_amount mt-4"></div>
              <div className="card_inners text-left">
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#000",
                    fontSize: "13px",
                    marginLeft: "5px",
                  }}
                >
                  This Month
                </p>
                {loading ? (
                  <div className="card_inners_amount mt-4">
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                ) : (
                  <h2
                    className="card_inners_amount"
                    style={{ color: "#c4da00", fontSize: "32px" }}
                  >
                    {Math.round(
                      (tonnes.total_tonnes_center + Number.EPSILON) * 100
                    ) / 100}
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "20px",
                        color: "#000",
                        fontSize: "13px",
                        marginLeft: "5px",
                      }}
                    >
                      tonnes collected
                    </span>
                  </h2>
                )}

              </div>
            </div>
            <div className="cardsss">
              <div className="card_inners">
                <h5
                  className="card_inners_amount"
                  style={{
                    fontSize: "16px",
                    paddingTop: "0",
                    marginTop: "0",
                  }}
                >
                  Collection Center Drop Off
                </h5>
                <div className="d-flex align-items-center justify-content-between">
                  <input
                    type="email"
                    className="form-control inputStyle mt-3"
                    placeholder="Enter drop off code"
                    value={dropOff}
                    onChange={(e) => setDropOff(e.target.value)}
                  />
                  <div
                    className="buttonLike ml-2 mt-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      getSingleDropOff(dropOff);
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        margin: "auto",
                      }}
                    >
                      &#62;
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="cardsss">
              <div className="cards_img"></div>
              <div className="card_inners">
                <h3
                  className="card_inners_amount"
                  style={{
                    fontSize: "16px",
                    paddingTop: "0",
                    marginTop: "0",
                  }}
                >
                  Collection Center List
                </h3>
                <div className="">
                  <div>
                    <p className="card_inners_p mb-0 text-left unreg">
                      View collection centers assigned to this aggregation
                      center
                    </p>
                  </div>
                  <div
                    className="d-flex justify-content-between mt-3"
                    onClick={() => {
                      // handleShowHousehold();
                    }}
                  >
                    <div></div>
                    <div
                      className="fpw"
                      onClick={() => history.push("/dashboard/collections")}
                    >
                      <p style={{ color: "#231F20", fontSize: "13px" }}>
                        Collection Center List
                        <span style={{ color: "#c4da00", marginLeft: "5px" }}>
                          &#62;
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="requestss">
            <div className="cardss">
              <div className="mb-2">
                <h4 className="dropOff__title mt-1 mr-4 mb-2">
                  <b>Scheduled Drop Offs</b> ({dropOffs.length})
                </h4>
              </div>
              <div>
                <div className="mt-3">
                  <div>
                    {/* <h4 className="dropOff__title mt-1 mr-4 pt-2">
                      <b>Today</b>
                    </h4> */}
                  </div>

                  <div>
                    {!Object.keys(groupedDate).length ? (
                      ""
                    ) : (
                      <div>
                        {Object.keys(groupedDate).length &&
                          Object.keys(groupedDate).map((req, index) => {
                            return (
                              <Result
                                req={req}
                                data={groupedDate[req]}
                                index={index}
                                weigh={weigh}
                                avatar={avatar}
                                handleShowComplete={handleShowComplete}
                                getSingleDropOff={getSingleDropOff}
                              />
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="requestss">
            <div className="cardss p-0 py-1 px-2">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <span className="d-flex align-items-center py-3 px-2">
                    <b>History </b>({historyData?.length})
                  </span>
                </div>
                <div
                  className="fpw mr-2"
                //   onClick={() => history.push("/dashboard/collections")}
                >
                  <p style={{ color: "#231F20", fontSize: "13px" }}>
                    Filter By
                    <span style={{ color: "#c4da00", marginLeft: "5px" }}>
                      &#62;
                    </span>
                  </p>
                </div>
              </div>

              <Table
                columns={columns}
                dataSource={historyData}
                size="middle"
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>
      <CompleteModal
        showComplete={showComplete}
        handleCloseComplete={handleCloseComplete}
        handleShowAuthenticate={handleShowAuthenticate}
        droppData={droppData}
        dropOfff={dropOfff}
        setDropOfff={setDropOfff}
      />
      <EnterCodeModal
        showCode={showCode}
        handleCloseCode={handleCloseCode}
        handleShowComplete={handleShowComplete}
      />

      <AuthenticateModal
        showAuthenticate={showAuthenticate}
        handleCloseAuthenticate={handleCloseAuthenticate}
        password={password}
        setPassword={setPassword}
        setDropOfff={setDropOfff}
        completeDropOff={completeDropOff}
        loading={loading}
      />
    </main>
  );
};

export default CollectionCenter;
