import React, { useState, useEffect } from "react";
import "./index.css";
import calendar from "../../../../../assets/images/calendar.png";
import avatar from "../../../../../assets/images/avatarr.png";
import axios from "axios";
import { switchEndpoint } from "../../../../../redux/actions/endpoints";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import UnregisteredHouseholdModal from "./components/modals/unregistered";
import UnregistereEnterCodeModal from "./components/modals/unregistered/enterDropOff";
import UnregistereWeighBottleModal from "./components/modals/unregistered/weighBottle";
import UnregistereDropCodeModal from "./components/modals/dropOffCode.js";
import AwaitingConfirmationModal from "./components/modals/dropOffCode.js/confirmation";
import PaymentRequestModal from "./components/modals/dropOffCode.js/paymentRequest";
import AuthenticateModal from "./components/modals/dropOffCode.js/authenticate";
import PaymentFailedModal from "./components/modals/dropOffCode.js/paymentFailed";
import PaymentSuccessfulModal from "./components/modals/dropOffCode.js/paymentSuccessful";
import PaymentReqModal from "./components/modals/unregistered/awaitt";
import ShowPModal from "./components/modals/dropOffCode.js/showP";
import Result from "./components/Result";
import AuthenticateModal2 from "./components/modals/unregistered/authenticate";

const PickUp = ({ setTitleName }) => {
  const [householdDrop, setHouseholdDrop] = useState([]);
  const [allDrop, setAllDrop] = useState([]);
  const [pricing, setPricing] = useState({});
  const [agentDrop, setAgentDrop] = useState([]);
  const [corporateDrop, setCorporateDrop] = useState([]);
  const [dropOff, setDropOff] = useState("");
  const [dropOffData, setDropOffData] = useState({});
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingH, setLoadinngH] = useState(false);
  const [loadingA, setLoadinngA] = useState(false);
  const [loadingU, setLoadinngU] = useState(false);
  const [groupedDate, setGroupedDate] = useState({});
  const [groupedDates, setGroupedDates] = useState({});
  const [groupedDatess, setGroupedDatess] = useState({});
  const [groupedDatesss, setGroupedDatesss] = useState({});
  const othersObj = {};
  const othersObjs = {};
  const othersObjss = {};
  const othersObjsss = {};
  const [steps, setSteps] = useState(0);
  const [selected, setSelected] = useState("all");


  const [isManual, setIsManual] = useState(false);
  const [corporatePaymentMethod, setCorporatePaymentMethod] = useState("");

  useEffect(() => {
    const manual = localStorage.getItem("manual_pricing");
    if (manual) {
      setIsManual(manual)
    }
  }, [])
  const request = [
    {
      name: "Victor Okoye",
      imageUrl: <img src={avatar} alt="avatarr" />,
      date: "29/03/22",
    },
    {
      name: "Victor Okoye",
      imageUrl: { avatar },
      date: "29/03/22",
    },
    {
      name: "Victor Okoye",
      imageUrl: { avatar },
      date: "29/03/22",
    },
    {
      name: "Victor Okoye",
      imageUrl: { avatar },
      date: "29/03/22",
    },
  ];

  const [showHousehold, setShowHousehold] = useState(false);
  const handleCloseHousehold = () => setShowHousehold(false);
  const handleShowHousehold = () => setShowHousehold(true);

  const [showCode, setShowCode] = useState(false);
  const handleCloseCode = () => setShowCode(false);
  const handleShowCode = () => setShowCode(true);

  const [showBottle, setShowBottle] = useState(false);
  const handleCloseBottle = () => setShowBottle(false);
  const handleShowBottle = () => setShowBottle(true);

  const [showDrop, setShowDrop] = useState(false);
  const handleCloseDrop = () => setShowDrop(false);
  const handleShowDrop = () => setShowDrop(true);

  const [showAwait, setShowAwait] = useState(false);
  const handleCloseAwait = () => setShowAwait(false);
  const handleShowAwait = () => setShowAwait(true);

  const [showAwaiting, setShowAwaiting] = useState(false);
  const handleCloseAwaiting = () => setShowAwaiting(false);
  const handleShowAwaiting = () => setShowAwaiting(true);

  const [showAuthenticate, setShowAuthenticate] = useState(false);
  const handleCloseAuthenticate = () => setShowAuthenticate(false);
  const handleShowAuthenticate = () => setShowAuthenticate(true);

  const [showPaymentS, setShowPaymentS] = useState(false);
  const handleClosePaymentS = () => setShowPaymentS(false);
  const handleShowPaymentS = () => setShowPaymentS(true);

  const [showPaymentF, setShowPaymentF] = useState(false);
  const handleClosePaymentF = () => setShowPaymentF(false);
  const handleShowPaymentF = () => setShowPaymentF(true);

  const [showPaymentR, setShowPaymentR] = useState(false);
  const handleClosePaymentR = () => setShowPaymentR(false);
  const handleShowPaymentR = () => setShowPaymentR(true);

  const [showPaymentRR, setShowPaymentRR] = useState(false);
  const handleClosePaymentRR = () => setShowPaymentRR(false);
  const handleShowPaymentRR = () => setShowPaymentRR(true);

  const [showAuthenticate2, setShowAuthenticate2] = useState(false);
  const handleCloseAuthenticate2 = () => setShowAuthenticate2(false);
  const handleShowAuthenticate2 = () => setShowAuthenticate2(true);

  const validate = () => {
    if (!dropOff) {
      toast.error("Drop Off Code not provided !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    getSingleDropOff();
  };

  const getStat = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/all-dropoff-today`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data);
        setStats(response.data);
        localStorage.setItem("stats", JSON.stringify(response.data));
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  };

  const getAllReq = () => {
    setLoadinngA(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/all-dropoff-requests`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setAllDrop(response.data.data);
        const result = response.data.data;
        result.forEach((element) => {
          const date_type = element.date_type;
          if (date_type in othersObjss) {
            othersObjss[date_type] = [...othersObjss[date_type], element];
          } else {
            othersObjss[date_type] = [element];
          }
        });
        setGroupedDatess(othersObjss);
        setLoadinngA(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadinngA(false);
      });
  };

  const getHouseholdReq = () => {
    setLoadinngA(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/dropoff-requests/household`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        setHouseholdDrop(response.data.data);
        const result = response.data.data;
        result.forEach((element) => {
          const date_type = element.date_type;
          if (date_type in othersObj) {
            othersObj[date_type] = [...othersObj[date_type], element];
          } else {
            othersObj[date_type] = [element];
          }
        });
        setGroupedDate(othersObj);
        setLoadinngA(false);
      })
      .catch((error) => {
        toast.error(error.response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadinngH(false);
      });
  };

  const getAgentReq = () => {
    setLoadinngA(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/dropoff-requests/agents`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        setAgentDrop(response.data.data);
        const result = response.data.data;
        result.forEach((element) => {
          const date_type = element.date_type;
          if (date_type in othersObjs) {
            othersObjs[date_type] = [...othersObjs[date_type], element];
          } else {
            othersObjs[date_type] = [element];
          }
        });
        setGroupedDates(othersObjs);
        setLoadinngA(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadinngA(false);
      });
  };

  const getCorporateReq = () => {
    setLoadinngA(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/dropoff-requests/corporate`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        setCorporateDrop(response.data.data);
        const result = response.data.data;
        result.forEach((element) => {
          const date_type = element.date_type;
          if (date_type in othersObjsss) {
            othersObjsss[date_type] = [...othersObjsss[date_type], element];
          } else {
            othersObjsss[date_type] = [element];
          }
        });
        setGroupedDatesss(othersObjsss);
        setLoadinngA(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadinngA(false);
      });
  };

  const getSingleDropOff = () => {
    setLoadinngU(true);
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/single-dropoff/${dropOff}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        localStorage.setItem("reference", JSON.stringify(response.data.data));
        setDropOffData(response.data.data);
        setLoadinngU(false);
        if (response.data.data.status === "accepted") {
          handleShowPaymentR();
        }
        if (response.data.data.status === "scheduled") {
          // if (response?.data?.data?.collection_center?.manual_pricing === true) {
          //   setIsManual(true)
          //   setCorporatePaymentMethod(response.data.data.collection_center.payment_method);
          // }
          handleShowDrop();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        setLoadinngU(false);
      });
  };

  const getDropOff = (code) => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/collection-center/single-dropoff/${code}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // console.log(response.data.data);
        localStorage.setItem("id", response.data.data.id);
        localStorage.setItem("reference", JSON.stringify(response.data.data));
        setDropOffData(response.data.data);
        // handleShowDrop();
        if (
          response.data.data.status === "initiated" &&
          response.data.data.owner.user_type === "unregistered"
        ) {
          handleShowPaymentRR();
          return;
        }
        if (
          response.data.data.status === "scheduled" &&
          response.data.data.owner.user_type === "unregistered"
        ) {
          console.log("hello");
          handleShowBottle();
          return;
        }

        if (response.data.data.status === "scheduled") {
          // if (response?.data?.data?.aggregation_center?.manual_pricing === true) {
          //   setIsManual(true)
          //   setCorporatePaymentMethod(response.data.data.aggregation_center.payment_method);
          // }
          handleShowDrop();
        }
        if (response.data.data.status === "initiated") {
          handleShowAwaiting();
        }
        if (response.data.data.status === "accepted") {
          handleShowPaymentR();
        }
      })
    // .catch((error) => {
    //   toast.error(error.response.data.message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     pauseOnHover: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // });
  };

  const getAllPricing = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${switchEndpoint}/all-waste-categories`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        //  console.log("waste categories ==>", response.data);
        setPricing(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    setTitleName("Drop Off Requests");
    getAllPricing();
    getStat();
    // getHouseholdReq();
    // getAgentReq();
    getAllReq();
    // }, [Object.keys(groupedDate).length || Object.keys(groupedDates).length]);
  }, [Object.keys(groupedDate).length || Object.keys(groupedDates).length]);

  // useEffect(() => {
  //   if (
  //     Object.keys(groupedDate).length >= 1 ||
  //     Object.keys(groupedDates).length >= 1 ||
  //     Object.keys(groupedDatess).length >= 1
  //   ) {
  //     getHouseholdReq();
  //     getAgentReq();
  //     getAllReq();
  //   }
  // }, []);

  useEffect(() => {
    if (showAwaiting) {
      setSteps(1)
    } else {
      setSteps(0)
    }
  }, [showAwaiting])

  return (
    <main>
      <div className="main__container">
        <div className="separatingDivs">
          <div>
            <div className="main__cardss main__cardx">
              <div className="cardsss cards_extra">
                {loading ? (
                  <div className="card_inners_amount mt-4">
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                ) : (
                  <div className="card_inners">
                    <h2
                      className="card_inners_amount"
                      style={{ color: "#c4da00", fontSize: "32px" }}
                    >
                      <span style={{ color: "#c4da00" }}>
                        {stats?.today_dropoffs_completed}
                      </span>
                      <span style={{ fontWeight: "400", fontSize: "20px" }}>
                        {` of `}
                      </span>
                      {stats?.today_dropoffs}
                    </h2>
                    <p className="card_inners_p mb-0">
                      Drop Offs completed today
                    </p>
                  </div>
                )}
              </div>
              <div className="cardsss">
                <div className="card_inners">
                  <h5
                    className="card_inners_amount"
                    style={{
                      fontSize: "16px",
                      paddingTop: "0",
                      marginTop: "0",
                    }}
                  >
                    Quick Drop Off
                  </h5>
                  <div className="d-flex align-items-center justify-content-between">
                    <input
                      type="email"
                      className="form-control inputStyle mt-3"
                      placeholder="Enter drop off code"
                      value={dropOff}
                      onChange={(e) => setDropOff(e.target.value)}
                    />
                    <div
                      className="buttonLike ml-2 mt-3"
                      style={{ cursor: "pointer" }}
                      onClick={validate}
                    >
                      {loadingU ? (
                        <span
                          style={{
                            color: "white",
                            marginLeft: "2px",
                            marginTop: "1px",
                          }}
                          className="text-center"
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "#fff",
                            textAlign: "center",
                            margin: "auto",
                          }}
                        >
                          &#62;
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="cardsss">
                <div className="cards_img"></div>
                <div className="card_inners">
                  <h3
                    className="card_inners_amount"
                    style={{
                      fontSize: "16px",
                      paddingTop: "0",
                      marginTop: "0",
                    }}
                  >
                    Unregistered Household?
                  </h3>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="card_inners_p mb-0 text-left unreg">
                        Manage drop off from unregistered users
                      </p>
                    </div>
                    <div
                      className="buttonLike"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleShowHousehold();
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          margin: "auto",
                        }}
                      >
                        &#62;
                      </span>
                    </div>
                    {/* <div
                      className="d-flex justify-content-between mt-3"
                      onClick={() => {
                        handleShowHousehold();
                      }}
                    >
                      <div></div>
                      <div className="fpw">
                        <p style={{ color: "#231F20", fontSize: "13px" }}>
                          Unregistered Households
                          <span style={{ color: "#c4da00" }}>&#62;</span>
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="req__grids">
              <div className="requests">
                <div className="cardss">
                  <div className="d-flex align-items-center">
                    <h4 className="dropOff__title mt-1">
                      Scheduled Drop Offs ({selected === "all" && allDrop?.length}{selected === "household" && householdDrop?.length}{selected === "agent" && agentDrop?.length}{selected === "corporate" && corporateDrop?.length})
                    </h4>
                    <div className="spaceTops ml-3">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                          onClick={() => {
                            setSelected("all");
                            getAllReq();
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          All
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="option2"
                          onClick={() => {
                            setSelected("household");
                            getHouseholdReq();
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          Household
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio3"
                          value="option3"
                          onClick={() => {
                            setSelected("agent");
                            getAgentReq();
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio3">
                          Agent
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio3"
                          value="option3"
                          onClick={() => {
                            setSelected("corporate");
                            getCorporateReq();
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio3">
                          Corporate Users
                        </label>
                      </div>
                    </div>
                  </div>
                  {selected === "all" && (
                    <div className=" mb-3">
                      <div>
                        <div className="mt-3">
                          {loadingA ? (
                            <div className="card_inners_amount mt-5 text-center">
                              <span>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          ) : (
                            <div>
                              {!Object.keys(groupedDatess).length ? (
                                <p className="text-center">No Data Available</p>
                              ) : (
                                <div>
                                  {Object.keys(groupedDatess).length &&
                                    Object.keys(groupedDatess).map((req, index) => {
                                      return (
                                        <Result
                                          req={req}
                                          data={groupedDatess[req]}
                                          index={index}
                                          getDropOff={getDropOff}
                                          avatar={avatar}
                                          calendar={calendar}
                                        />
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {selected === "corporate" && (
                    <div className=" mb-3">
                      <div>
                        <div className="mt-3">
                          {loadingA ? (
                            <div className="card_inners_amount mt-5 text-center">
                              <span>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          ) : (
                            <div>
                              {!Object.keys(groupedDatesss).length ? (
                                <p className="text-center">No Data Available</p>
                              ) : (
                                <div>
                                  {Object.keys(groupedDatesss).length &&
                                    Object.keys(groupedDatesss).map((req, index) => {
                                      return (
                                        <Result
                                          req={req}
                                          data={groupedDatesss[req]}
                                          index={index}
                                          getDropOff={getDropOff}
                                          avatar={avatar}
                                          calendar={calendar}
                                        />
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {selected === "household" && (<div className=" mb-3">
                    <div>
                      <div className="mt-3">
                        {/* <h6 className="dropOff__title mt-1">Today</h6> */}
                        {loadingA ? (
                          <div className="card_inners_amount mt-5 text-center">
                            <span>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        ) : (
                          <div>
                            {!Object.keys(groupedDate).length ? (
                              <p className="text-center">No Data Available</p>
                            ) : (
                              <div>
                                {Object.keys(groupedDate).length &&
                                  Object.keys(groupedDate).map((req, index) => {
                                    return (
                                      <Result
                                        req={req}
                                        data={groupedDate[req]}
                                        index={index}
                                        getDropOff={getDropOff}
                                        avatar={avatar}
                                        calendar={calendar}
                                      />
                                    );
                                  })}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>)}
                  {selected === "agent" && (<div className=" mb-3">
                    <div>
                      <div className="mt-3">
                        {loadingA ? (
                          <div className="card_inners_amount mt-5 text-center">
                            <span>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        ) : (
                          <div>
                            {!Object.keys(groupedDates).length ? (
                              <p className="text-center">No Data Available</p>
                            ) : (
                              <div>
                                {Object.keys(groupedDates).length &&
                                  Object.keys(groupedDates).map(
                                    (req, index) => {
                                      return (
                                        <Result
                                          req={req}
                                          data={groupedDates[req]}
                                          index={index}
                                          getDropOff={getDropOff}
                                          avatar={avatar}
                                          calendar={calendar}
                                        />
                                      );
                                    }
                                  )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>)}

                </div>
              </div>

              {/* <div className="requestss">
                <div className="cardss">
                  <div className=" mb-3">
                    <div className="">
                      <h4 className="dropOff__title mt-1">
                        Agent Scheduled Drop Offs ({agentDrop.length})
                      </h4>
                    </div>
                    <div>
                      <div className="mt-3">
                        {loadingA ? (
                          <div className="card_inners_amount mt-5 text-center">
                            <span>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        ) : (
                          <div>
                            {!Object.keys(groupedDates).length ? (
                              ""
                            ) : (
                              <div>
                                {Object.keys(groupedDates).length &&
                                  Object.keys(groupedDates).map(
                                    (req, index) => {
                                      return (
                                        <Result
                                          req={req}
                                          data={groupedDates[req]}
                                          index={index}
                                          getDropOff={getDropOff}
                                          avatar={avatar}
                                          calendar={calendar}
                                        />
                                      );
                                    }
                                  )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <UnregisteredHouseholdModal
          showHousehold={showHousehold}
          handleCloseHousehold={handleCloseHousehold}
          handleShowCode={handleShowCode}
          handleShowPaymentRR={handleShowPaymentRR}
        />
        <UnregistereEnterCodeModal
          showCode={showCode}
          handleCloseCode={handleCloseCode}
          handleShowBottle={handleShowBottle}
        />

        <UnregistereDropCodeModal
          showDrop={showDrop}
          handleCloseDrop={handleCloseDrop}
          dropOffData={dropOffData}
          handleShowAwait={handleShowAwait}
          handleShowAwaiting={handleShowAwaiting}
          steps={steps}
          setSteps={setSteps}
          handleShowPaymentRR={handleShowPaymentRR}
          getHouseholdReq={getHouseholdReq}
          getAgentReq={getAgentReq}
          pricing={pricing}
          isManual={isManual}
          corporatePaymentMethod={corporatePaymentMethod}
        />

        <AwaitingConfirmationModal
          showAwait={showAwait}
          handleCloseAwait={handleCloseAwait}
          dropOffData={dropOffData}
          handleShowPaymentR={handleShowPaymentR}
        />

        <UnregistereWeighBottleModal
          showBottle={showBottle}
          handleCloseBottle={handleCloseBottle}
          handleShowPaymentRR={handleShowPaymentRR}
          dropOffData={dropOffData}
          getHouseholdReq={getHouseholdReq}
          getAgentReq={getAgentReq}
        />

        <PaymentRequestModal
          showPaymentR={showPaymentR}
          handleClosePaymentR={handleClosePaymentR}
          handleShowAuthenticate={handleShowAuthenticate}
          dropOffData={dropOffData}
        />

        <PaymentFailedModal
          showPaymentF={showPaymentF}
          handleClosePaymentF={handleClosePaymentF}
        />

        <AuthenticateModal
          showAuthenticate={showAuthenticate}
          handleCloseAuthenticate={handleCloseAuthenticate}
          dropOffData={dropOffData}
          handleShowPaymentS={handleShowPaymentS}
          handleShowPaymentF={handleShowPaymentF}
        />

        <PaymentSuccessfulModal
          showPaymentS={showPaymentS}
          handleClosePaymentS={handleClosePaymentS}
        />

        <PaymentSuccessfulModal
          showPaymentF={showPaymentF}
          handleClosePaymentF={handleClosePaymentF}
        />

        <PaymentReqModal
          showAwaiting={showAwaiting}
          handleCloseAwaiting={handleCloseAwaiting}
          dropOffData={dropOffData}
          handleShowPaymentR={handleShowPaymentR}
          steps={steps}
          setSteps={setSteps}
          getAllReq={getAllReq}
        />

        <ShowPModal
          showPaymentRR={showPaymentRR}
          handleClosePaymentRR={handleClosePaymentRR}
          handleShowAuthenticate2={handleShowAuthenticate2}
        />

        <AuthenticateModal2
          showAuthenticate2={showAuthenticate2}
          handleCloseAuthenticate2={handleCloseAuthenticate2}
          dropOffData={dropOffData}
          handleShowPaymentS={handleShowPaymentS}
          handleShowPaymentF={handleShowPaymentF}
        />
      </div>
    </main>
  );
};

export default PickUp;
