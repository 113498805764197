import React, { useState, useEffect } from "react";
import "./index.css";
import newLogo from "../../assets/images/newLogo.png";

const DeleteAccountFinal = () => {
  return (
    <div>
      <div className="row noMarginPadding">
        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-8 col-xs-12 PaddingLeft-10">
          <div className="container pl-4 pt-4 PaddingLeft-10">
            <div style={{ width: "150px" }}>
              <img src={newLogo} alt="logo" style={{ width: "100%" }} />
            </div>
            {/* <div className="w-75 ml-5 mt-5 containerWidth">
              <h5 className="headerText headerh5">
                This action cannot be completed! Withdraw your funds first
              </h5>
              <p className="">
                You can only delete your account when your Switch wallet balance
                is N0.00. Visit the Switch App and withdraw your funds to delete
                your account.
              </p>
              <div className="validateOtp">
                <button
                  type="button"
                  className="btn loginBtn btn-sm btn-block mt-4"
                  onClick={() => {}}
                >
                  Go to App
                </button>
              </div>
            </div>{" "} */}
          </div>

          <div className="d-flex justify-content-center align-items-center m-auto h-50">
            <div className="w-50 ml-5 mt-5 containerWidth">
              <h5 className="headerText headerh5 text-center">
                Your Switch Account has been Deleted
              </h5>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-4 rightImg"></div>
      </div>
    </div>
  );
};

export default DeleteAccountFinal;
